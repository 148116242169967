const Faqs = [
  {
    id: 1,
    question: 'How does AI-powered ad creation work?',
    answer: "AI-powered ad creation utilizes advanced algorithms to analyze input, whether it's text or guiding images. The AI technology then generates visually appealing ad variations based on the provided input, resulting in compelling and engaging advertisements."
  },
  {
    id: 2,
    question: 'Can I create ads using AI without any design skills?',
    answer: 'Absolutely! Our AI-powered platform is designed for users of all skill levels. You can create professional-quality ads without any design skills or technical expertise. Simply input your ad requirements, and let AI handle the rest.'
  },
  {
    id: 3,
    question: 'What social media platforms are supported for ad publishing?',
    answer: 'Our platform supports ad publishing on popular social media platforms such as Facebook and Instagram. You can easily connect your accounts, customize your ads, and publish them seamlessly on these platforms to reach your target audience effectively.'
  },
  {
    id: 4,
    question: 'Are the generated ad sizes optimized for different platforms?',
    answer: 'Yes, our AI technology automatically generates optimized ad sizes for various social media platforms, including Facebook, Instagram, TikTok, Snapchat, Twitter, and more. You can download the ads in the perfect sizes required for each platform, ensuring maximum visibility and engagement.'
  },
  {
    id: 5,
    question: 'Can I customize the AI-generated ads to suit my brand?',
    answer: "Absolutely! Our AI-generated ads serve as a starting point, but you have complete control to customize them according to your brand's aesthetics and messaging. You can include brand logos and product images to suit your brand.'"
  },
  {
    id: 6,
    question: 'How long does it take to create an ad using AI?',
    answer: 'Creating ads using AI is a quick and efficient process. Depending on the complexity of your input, you can generate ad variations within minutes. This saves you time and allows you to focus on other aspects of your marketing strategy.'
  },
  {
    id: 7,
    question: 'How can AI help maximize conversions from Facebook and Instagram ads?',
    answer: 'Our AI algorithms optimize your ads based on data-driven insights. By analyzing user behavior and engagement patterns, AI helps identify the most effective ad elements and targeting strategies. This enables you to achieve higher conversions and maximize the ROI of your Facebook and Instagram ad campaigns.'
  },
  {
    id: 8,
    question: 'What kind of support is available if I encounter any issues?',
    answer: 'We provide dedicated customer support to assist you with any questions or issues you may encounter. Our knowledgeable team is available to provide guidance, troubleshoot technical problems, and ensure a smooth experience while using our AI-powered ad creation platform. You can raise help tickets from within out tool interface.'
  },
];

export default Faqs;

import React, {useContext, useEffect, useRef} from "react";
import { AppContext } from "../../appContext";
import { Modal } from "frontend-core";

const ExhaustedCreditsModal = () => {
  const { 
    isExhaustedCreditModalOpen, 
    setExhaustedCreditModalOpen, 
    user,
    userMaxCreditLimit,
    isSelectSubModalOpen,
    setSelectSubModalOpen
  } = useContext(AppContext);

  const upgradeClickedRef = useRef<boolean>(false);

  useEffect(() => {
    let modalOverlayIndex = 1;
    if (window.location.href.includes('manage/')) {
      modalOverlayIndex = 2;
    }

    let modalOverlay = document.getElementsByClassName('modal-overlay')[modalOverlayIndex] as HTMLDivElement;
    if (!modalOverlay) {
      return;
    }
    if (isExhaustedCreditModalOpen) {
      modalOverlay.style.zIndex = '9999';
    }
    else {
      modalOverlay.style.zIndex = '9999';
    }
  }, [isExhaustedCreditModalOpen]);

  useEffect(() => {
    if (upgradeClickedRef.current) {
      let modalBody = document.getElementsByClassName('upgrade-modal')[0] as HTMLDivElement;
      if (!modalBody) {
        return;
      }
      if (isSelectSubModalOpen && modalBody.parentElement) {
        modalBody.parentElement.style.zIndex = '9999';
        upgradeClickedRef.current = false;
      }
      else {
        if (modalBody.parentElement) {
          modalBody.parentElement.style.zIndex = '1000';
          upgradeClickedRef.current = false;
        }
      }
    }
  }, [isSelectSubModalOpen]);


  const getTitle = () => {
    return 'Out of Credits';
  }

  const getBodyText = () => {
    return (
      <>
        Oops! You're out of AI credits. Upgrade your plan for more AI assets
      </>
    )    
  }

  const getFooterPart = () => {
    return (
      <div>
        <button 
          onClick={() => {
            setExhaustedCreditModalOpen(false);
            window['lockedElementClicked'] = true;
            setSelectSubModalOpen(true);
            upgradeClickedRef.current = true;
          }}
          className="btn">Upgrade</button>
      </div>
    )
  }

  return (
    <Modal
      show={isExhaustedCreditModalOpen}
      setShow={setExhaustedCreditModalOpen}
      title={getTitle()}
    >
      <div className="exhausted-credit-modal">
        <div className="img-container">
          <img src="https://media.useblitz.com/assets/images/credit-card.svg" />
        </div>
        <div className="body-text-container">
          {getBodyText()}
        </div>
        <div className="footer-container">
          {getFooterPart()}
        </div>
      </div>
    </Modal>
  )
};

export default ExhaustedCreditsModal;
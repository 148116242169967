import { Modal } from 'frontend-core';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Routes from '../../common/routes';

interface INotFound {
  head?: string | JSX.Element;
  content?: string | JSX.Element;
  action?: () => void;
}

const ErrorPage: FC<INotFound> = ({
  head, content, action
}) => {

  const navigate = useNavigate();

  const goBackHandler = () => {
    if (action) {
      action();
      return;
    }

    // default action
    navigate(Routes.LIBRARY);
  }

  return (
    <div className="gradient red">
      <Modal
        show={true}
        showClose={false}
        className="error-page center"
      >
        <div className="error-page-container">
          <div className="sigh-container">
            <div className="icon sigh"></div>
          </div>

          <h2 className="f-32 error-page-head">{head || 'Oops! Something went wrong'}</h2>
          <p className="mb-20">{content || 'Please refresh the page to continue'}</p>

          <button
            onClick={goBackHandler}
            className="btn v3 w-150"
          >Go Back</button>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorPage;

import React, {useContext, useEffect, useMemo, useState} from 'react';

import AfterHeader from '../AfterHeader';
import LandingLayout from '../Layout';
import { useWindowResize } from 'frontend-core';
import Posts, { IBlogPost } from './Posts';
import RenderPosts from './RenderPosts';
import Authenticate from '../../Authenticate';
import { AppContext } from '../../../appContext';
import MetaTags from '../../../common/MetaTags';
import Routes from '../../../common/routes';

const Blog: React.FC = () => {

  const { authModal } = useContext(AppContext);

  const [postIndex, setPostIndex] = useState<number>(0);
  
  const post = useMemo(() => {
    return Posts ? Posts[postIndex] : null;
  }, [postIndex]);

  const previous = () => {
    const index = postIndex === 0 ? Posts.length - 1 : postIndex - 1;
    setPostIndex(index);
  };

  const next = () => {
    const index = postIndex === Posts.length - 1 ? 0 : postIndex + 1;
    setPostIndex(index)
  };

  return (
    <LandingLayout>
      <>
        <MetaTags
          title="Ad Creative Insights: Explore Our Blog for AI Advertising Tips and Trends"
          description="Stay updated with the latest AI advertising trends and expert advice on ad creative strategies. Our blog offers valuable insights, tips, and best practices to optimize your ad campaigns and drive maximum impact."
        />
        <div className="home landing-v2">
          <section className="peach-container blog-head-container-top">
            <div className="pad-80">
              <div className="layout-container">
                <div className="flex direction-column aic">
                  <h2 className="center">Welcome to <span className="orange">our Blog</span></h2>
                  <h5 className="center mt-30 mb-30 mw-480">Insights, Tips, and Trends: Explore Our Blog for Expert Advice on AI-Powered Ad Creation</h5>
                </div>
              </div>
            </div>
          </section>

          <section className="blog-head-container-bottom">
            <div className="pad-80">
              <div className="layout-container">
                <div className="blog-container">
                  {post && (
                    <div className="editor blog-head">
                      <div
                        className="blog-hero"
                        style={{ backgroundImage: `url(${post.image})` }}
                      ></div>
                      <div className="blog-details">
                        <div className="title">{post.subHead}</div>
                        <div className="sub-head">{post.title}</div>
                        <p className="description">{post.description}</p>
                        <div className="date-text">{post.date}</div>
                        <div className="blog-navigation">
                          <div className="icon previous"
                            onClick={previous}
                          ></div>
                          <div className="icon next"
                            onClick={next}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div className="blog">
          <RenderPosts posts={Posts} />
        </div>
      </>
    </LandingLayout>
  );
};

export default Blog;

import { SessionStorage, VideoPlayer } from 'frontend-core';
import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../appContext';
import Authenticate from '../../Authenticate';

import { LandingContext } from '../LandingContext';

import LandingLayout from '../Layout';
import constants from '../../../constants';
import AllFaqs from './AllFaqs';
import MetaTags from '../../../common/MetaTags';
import Routes from '../../../common/routes';

const Faqs: React.FC = () => {

  const { authModal } = useContext(AppContext);

  const toggleFaq = (faq) => {
    const { id } = faq;
    const faqElement = document.getElementById(`faq-${id}`);
    if (faqElement) {
      if (faqElement.classList.contains('active')) {
        faqElement.classList.remove('active');
      } else {
        faqElement.classList.add('active');
      }
    }
  };

  return (
    <LandingLayout>
      <>
        <MetaTags
          title="Frequently Asked Questions: Your Queries Answered on AI-Powered Ad Creation"
          description="Find answers to common questions about AI-powered ad creation. Explore our FAQ section to learn more about our AI technology, features, benefits, and how to make the most of our ad creation platform"
        />
        <div className="home landing-v2">

          <section className="peach-container">
            <div className="pad-80">
              <div className="layout-container">
                <div className="flex direction-column aic">
                  <h2 className="center">Frequently Asked <span className="orange">Questions</span></h2>
                  <h5 className="center mt-30 mb-30" style={{ maxWidth: '480px' }}>Frequently Asked Questions: Get Answers to Your Queries About AI-Powered Ad Creation</h5>
                </div>
              </div>
            </div>
          </section>

          <section className="white-container">
            <div className="">
            <div className="layout-container">
                <div className="faqs pad-80">
                  {AllFaqs.map(f => (
                    <div
                      className="faq"
                      id={`faq-${f.id}`}
                      key={`faq-${f.id}`}
                      onClick={() => toggleFaq(f)}
                    >
                      <h4>{f.question}</h4>
                      <div className="answer">
                        <p>{f.answer}</p>
                      </div>
                      <div
                        className="toggle"
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </LandingLayout>
  );
};

export default Faqs;

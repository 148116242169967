import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AfterHeader from '../AfterHeader';
import LandingLayout from '../Layout';
import Posts, { IBlogPost } from './Posts';
import Routes from '../../../common/routes';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { shuffleArray } from '../../../common/utils';
import RenderPosts from './RenderPosts';
import Authenticate from '../../Authenticate';
import { AppContext } from '../../../appContext';
import MetaTags from '../../../common/MetaTags';

const BlogPost: FC = () => {

  const params = useParams();
  const navigate = useNavigate();
  const { authModal } = useContext(AppContext);
  const [post, setPost] = useState<IBlogPost | null>(null);

  const getBlogPost = () => {
    const { slug } = params;
    const blogPost = Posts.find(p => p.slug === slug);
    if (blogPost) {
      setPost(blogPost);
    } else {
      navigate(Routes.BLOG);
    }
  };

  const getRandomPosts = useCallback(() => {
    const { slug } = params;
    const posts = shuffleArray(Posts.filter(p => p.slug !== slug));
    return [posts[0], posts[1], posts[2]];
  }, []);

  useEffect(() => {
    getBlogPost();
  }, []);

  useEffect(() => {
    getBlogPost();
  }, [params.slug]);

  return (
    <LandingLayout>
      <>
        {post && (
          <MetaTags
            title={post.title}
            description={`${post.subHead} - ${post.title}`}
          />
        )}

        <div className="home landing-v2">
          <section className="peach-container blog-head-container-top">
            <div className="pad-40">
            </div>
          </section>

          <section className="blog-head-container-bottom">
            <div className="pad-80">
              <div className="layout-container">
                <div className="blog-container">
                  {post && (
                    <div className="editor blog-head">
                      <div
                        className="blog-hero"
                        style={{ backgroundImage: `url(${post.image})` }}
                      ></div>
                      <div className="blog-details">
                        <div className="title mt-30">{post.subHead}</div>
                        <h2>{post.title}</h2>
                        <div className="date-text">{post.date}</div>
                        <div className="author">
                          {post.authorImage && (
                            <div
                              className="author-image"
                              style={{ backgroundImage: `url(${post.authorImage})` }}
                            ></div>
                          )}
                          By {post.author}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <div className="blog blog-post">
            {post?.image && (
              <div className="blog-hero-image">
                <img src={post.image} />
              </div>
            )}
            {post && (
              <div className="blog-content">
                <h2 className="title">{post.title}</h2>
                {post.content}
              </div>
            )}

            <h2 className="center mt-100 mb-40">Similar Other Posts</h2>

            <RenderPosts posts={getRandomPosts()} className="jc-center" />

          </div>
        </div>
      </>
    </LandingLayout>
  )
};

export default BlogPost;

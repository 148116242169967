import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './common/ErrorBoundary';
import { getFullEnv } from './constants/env';
import ErrorPage from './pages/errorPage';


// sentry for non local errors only
if (getFullEnv() !== 'local') {
  Sentry.init({
    dsn: "https://06b2526c5ccd4fa7ab822cf847fa8391@o4505092115464192.ingest.sentry.io/4505092389404672",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: getFullEnv(),
  });
}

// const container = document.getElementById('app');
const root = createRoot(
  document.getElementById('root') as HTMLElement
);

const fallbackPage = (
  <ErrorPage
    action={() => {window.location.reload()} }
  />
);

root.render(
  <ErrorBoundary fallback={fallbackPage}>
    <App />
  </ErrorBoundary>
  // <Sentry.ErrorBoundary fallback={<p>Something went wrong while rendering the app.</p>} showDialog>
  //   <App />
  // </Sentry.ErrorBoundary>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { useContext, useState, useEffect } from 'react';

import { AppContext } from '../../../appContext';
import LandingLayout from '../Layout';
import constants from '../../../constants';
import Faqs from './HomeFaqs';
import Routes from '../../../common/routes';
import { useNavigate } from 'react-router-dom';
import MetaTags from '../../../common/MetaTags';

const Home: React.FC = () => {

  const { authModal } = useContext(AppContext);
  const navigate = useNavigate();

  const toggleFaq = (faq) => {
    const { id } = faq;
    const faqElement = document.getElementById(`faq-${id}`);
    if (faqElement) {
      if (faqElement.classList.contains('active')) {
        faqElement.classList.remove('active');
      } else {
        faqElement.classList.add('active');
      }
    }
  };

  return (
    <LandingLayout>
      <>
        <MetaTags
          title="AI-Powered Ad Creation: Create Stunning Ads with Ease | SmartBlitzAI"
          description="Create stunning ads effortlessly with our AI-powered ad creator. Boost your Facebook and Instagram ad campaigns and social media marketing with ease. Get started with SmartBlitzAI today"
        />
        <div className="home landing-v2">

          <section className="peach-container hero-container-top">
            <div className="pad-80">
              <div className="layout-container">
                <div className="flex direction-column aic">
                  <h2 className="center">
                    Create and Publish
                    <br />
                    <div className="orange">AI-Powered Ads</div>
                  </h2>
                  <h5>Create stunning ads using AI in minutes. Drive conversions and reach your target audience effortlessly.</h5>
                  <button
                    className="btn v3 large"
                    onClick={e => authModal.showModal[0].fn(e, 'sign-up')}
                  >
                    {/* Start Creating Ads Now */}
                    Create Ads for Free
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="white-container hero-container-bottom">
            <div className="pad-80">
              <div className="layout-container">
                <div className="hero-image">
                  <img
                    src={`${constants.imagesUrl}landing/hero-image-24-fps.gif`}
                  />
                </div>

                <div className="flex flex-container mt-60">
                  <div className="left">
                    <div className="content">
                      <h3>AI-Powered Ad Creation from Text Description</h3>
                      <h4>Transform Your Ideas into Stunning Ads with AI</h4>
                      <p>Experience the power of AI technology. Simply input your ideas as text, and our advanced AI technology will generate stunning ads that capture your vision. No design skills required!</p>
                      <button
                        className="btn v3"
                        onClick={e => {
                          navigate(Routes.TEXT_TO_AD);
                        }}
                      >
                        {/* Create Ads With Text Description */}
                        Create Ads for Free
                      </button>
                    </div>
                  </div>
                  <div className="right">
                    <div className="sub-hero-image">
                    <img
                      src={`${constants.imagesUrl}landing/hero-text-to-image-800.gif`}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="peach-container">
            <div className="pad-50">
              <div className="layout-container">
                <div className="flex flex-container">
                  <div className="left">
                    <div className="sub-hero-image">
                      <img
                        src={`${constants.imagesUrl}landing/hero-image-to-image-800-v2.gif`}
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="content">
                      <h3>AI-Powered Ad Creation from Guiding Image</h3>
                      <h4>Bring Your Vision to Life with AI Ads</h4>
                      <p>Upload a guiding image, and our AI-powered platform will generate three custom ad variations based on your vision. Effortlessly create engaging ads that resonate with your audience.</p>
                      <button
                        className="btn v3"
                        onClick={e => {
                          navigate(Routes.IMAGE_TO_AD);
                        }}
                      >
                        {/* Create Ads With Image Input */}
                        Create Ads for Free
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="white-container">
            <div className="pad-80">
              <div className="layout-container">
                <div className="flex flex-container">
                  <div className="left">
                    <div className="content">
                      <h3>AI-Generated Ads for Any Social Media Platform</h3>
                      <h4>Expand Your Reach with Optimized Ad Sizes</h4>
                      <p>Create a single ad and let our AI technology generate optimized versions for Facebook, Instagram, TikTok, Snapchat, Twitter, and more. Download your ads in the perfect sizes for each platform.</p>
                      <button
                        className="btn v3"
                        onClick={e => authModal.showModal[0].fn(e, 'sign-up')}
                      >
                        {/* Download Optimized Ad Sizes */}
                        Create Ads for Free
                      </button>
                    </div>
                  </div>
                  <div className="right">
                    <div className="sub-hero-image">
                      <img
                        src={`${constants.imagesUrl}landing/hero-platforms-800-v2.gif`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="peach-container">
            <div className="pad-50">
              <div className="layout-container mt-60 mb-60">
                <div className="flex flex-container">
                  <div className="left">
                    <div className="sub-hero-image">
                      <img
                        src={`${constants.imagesUrl}landing/hero-campaigns-800.gif`}
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="content">
                      <h3>Effortless Ad Publishing on Facebook and Instagram</h3>
                      <h4>Reach Your Target Audience in No Time</h4>
                      <p>Connect your ad account, craft compelling ads, customize your targeting options, and publish your ads on Facebook and Instagram within minutes. Increase your brand visibility and drive results effortlessly.</p>
                      <button
                        className="btn v3"
                        onClick={e => authModal.showModal[0].fn(e, 'sign-up')}
                      >
                        {/* Publish Ads Now */}
                        Create Ads for Free
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="white-container">
            <div className="pad-80">
              <div className="layout-container mt-60 mb-60">
                <div className="flex flex-container">
                  <div className="left">
                    <div className="content">
                      <h3>Boost Conversions with AI-Enhanced Ads</h3>
                      <h4>Optimize Your Campaigns for Maximum Impact</h4>
                      <p>Leverage our machine learning algorithms to optimize your ads for Facebook and Instagram. Increase engagement, click-through rates, and conversions with data-driven insights.</p>
                      <button
                        className="btn v3"
                        onClick={e => authModal.showModal[0].fn(e, 'sign-up')}
                      >
                        {/* Supercharge Your Ads */}
                        Create Ads for Free
                      </button>
                    </div>
                  </div>
                  <div className="right">
                    <div className="sub-hero-image">
                      <img
                        src={`${constants.imagesUrl}landing/hero-analytics-800.gif`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="layout-container faq-container">
                <div className="mt-60">
                  <h3 className="center mb-50">Frequently Asked Questions</h3>

                  <div className="faqs">
                    {Faqs.map(f => (
                      <div
                        className="faq"
                        id={`faq-${f.id}`}
                        key={`faq-${f.id}`}
                        onClick={() => toggleFaq(f)}
                      >
                        <h4>{f.question}</h4>
                        <div className="answer">
                          <p>{f.answer}</p>
                        </div>
                        <div
                          className="toggle"
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </>
    </LandingLayout>
  );
};

export default Home;

import { createContext, useState, useEffect, useRef } from "react";
import { User, IAdAccountsInfo, SocialAccountInfo, INumOfAIGenerationResponses, Permissions } from "./common/model";
import Axios from "axios";
import { getLoggedInData, getCardDetails, loginUnregisteredUser } from "./api/account-api";
import { getAllUserDashboardMetrics, getInfraParam } from "./api/common";
import {pollCampaignStatus} from "./api/campaigns-api";
import { utils } from "./common/utils";

import LocalBase from 'localbase';
import { getPlansByGroup, getUserPlan } from "./api";
import axios from "axios";
import { SessionStorage, Storage } from "frontend-core";
import { sendFbPixelToServer } from "./api/analytics-api";
import constants from "./constants";

// todo:
// `currentPlayingVideo` can be localised to home page.
// e.g. by creating ui/src/pages/home/context.tsx
// Logged in state can be stored in appContext instead of passing
// as props.


function getUserInfo() {
  const userInfoStr = localStorage.getItem("userInfo");
  if (userInfoStr) {
    return JSON.parse(userInfoStr);
  } else {
    return null;
  }
}

function loggedIn() {
  return (getUserInfo() != null);
}

function hasCMSAccess() {
  let userInfo = getUserInfo();
  if(userInfo != null && userInfo.permissions && userInfo.permissions.includes("CMS_ACCESS")) {
    return true;
  }
  return false;
}

function isUserSuperAdmin() {
  let userInfo = getUserInfo();
  if(userInfo != null && userInfo.permissions && userInfo.permissions.includes("SUPER_USER")) {
    return true;
  }
  return false;
}

function isUserIntermediate() {
  let userInfo = getUserInfo();
  if(userInfo != null && userInfo.permissions && userInfo.permissions.includes("UI_TOOL_INTERMEDIATE")) {
    return true;
  }
  return false;
}

export const isEnterpriseUser = (): boolean => {
  let user = getUserInfo();
  if (user?.permissions.includes(Permissions.SUPER_USER)) {
    return true;
  }

  if (user?.premiumTier === 100 || user?.premiumTier === 10 || user?.premiumTier === -1) {
    return false;
  }

  return true;
};

export const isPaidUser = (): boolean => {
  let user = getUserInfo();
  if (user?.permissions.includes(Permissions.SUPER_USER)) {
    return true;
  }
  // 10: free user, >100 paid user
  if (user?.premiumTier >= 100) {
    return true;
  }
  return false;
};

const initialConnectedAccounts: IAdAccountsInfo = {
  credentialsMap: {},
  maxVariants: 0,
  unProcessedBatch: [],
  activePlatform : 'FACEBOOK',
  isLoading: true
};

export interface IAppContext {
  currentPlayingVideo: string;
  setCurrentPlayingVideo: (currentVideo: string) => void;
  user: User | null;
  isTeamUser: () => boolean;
  setUser: Function;
  refreshUser: Function;
  isUserLoggedIn: boolean;
  userHasCMSAccess: boolean;
  isUserSuperAdmin: boolean;
  isUserIntermediate: boolean;
  authModal: {
    linkType: string;
    setLinkType: Function;
    isModalVisible: boolean;
    setIsModalVisible: Function;
    showModal: any;
    showSuccessModal: boolean;
    setShowSuccessModal: Function;
    showCustomPlanEnquirySuccess: boolean;
    setShowCustomPlanEnquirySuccess: Function;
  };
  subscriptionModal: {
    isOpen: boolean;
    setIsOpen: Function;
    currentStep: number;
    setCurrentStep: Function;
    isUpgrading: boolean;
    setIsUpgrading: Function;
  };
  connectedAccounts: IAdAccountsInfo;
  setConnectedAccounts: Function;
  isVariantConfigVisible: boolean;
  setIsVariantConfigVisible: Function;
  populateAccounts: Function;
  getAdAccountOptions: Function;
  duplicateAsync : Function;
  uploadProgress: {};
  setUploadProgress: Function;
  columnConfigs: any[];
  setColumnConfigs: Function;
  activeColumnConfig: string;
  setActiveColumnConfig: Function;
  metrics: any,
  setMetrics: Function;
  allMetrics: any,
  setAllMetrics: Function;
  defaultColumns: string,
  setDefaultColumns: Function,
  duplicateJobsQueue : any[],
  setDuplicateJobsQueue: Function;
  snackbarOpened: boolean;
  setSnackbarOpened: Function;
  setYScrollbarPosition: Function;
  setDataGridBodyHeight: Function;
  isSelectSubModalOpen: boolean;
  setSelectSubModalOpen: Function;
  userPlan: any;
  setUserPlan: Function;
  isPlansFetching: boolean;
  setPlansFetching: Function;
  subscriptionPlans: any[];
  setSubscriptionPlans: Function;
  isFetchingCreditCard: boolean;
  setFetchingCreditCard: Function; 
  userCard: any;
  setUserCard: Function;
  updateUser: Function;
  userMaxCreditLimit: number;
  setUserMaxCreditLimit: Function;
  userMinCreditLimit: number;
  setUserMinCreditLimit: Function;
  pricingPerHundred: number;
  setPricingPerHundred: Function;
  trialCreditLimit: number;
  setTrialCreditLimit: Function;
  isPopulatingAccounts: boolean;
  setPopulatingAccounts: Function;
  isLiteUser: () => boolean;
  isEnterpriseUser: () => boolean;
  hasProductTourActive: boolean;
  setHasProductTourActive: Function;
  productTourStep: number;
  setProductTourStep: Function;
  shouldRefreshCampaignList: boolean;
  setShouldRefreshCampaignList: Function;
  getPurchaseEvent: Function;
  displayJustPublishedModal: boolean;
  setDisplayJustPublishedModal: Function;
  isExhaustedCreditModalOpen: boolean;
  setExhaustedCreditModalOpen: Function;
  isTopupCreditModalOpen: boolean;
  setTopupCreditModalOpen: Function;
  showPaymentFailureModal: boolean;
  setShowPaymentFailureModal: Function;
  numOfAIResponses: INumOfAIGenerationResponses;
  setNumOfAIResponses: Function;
  isUserFree: Function;
  isUserLite: Function;
  isUserEnterprise: Function;
  isUserInternal: Function;
}

const defaultContext: IAppContext = {
  currentPlayingVideo: '',
  setCurrentPlayingVideo: () => {},
  user: null,
  isTeamUser: () => false,
  setUser: () => {},
  refreshUser: () => {},
  isUserLoggedIn: loggedIn(),
  userHasCMSAccess: hasCMSAccess(),
  isUserSuperAdmin: isUserSuperAdmin(),
  isUserIntermediate: isUserIntermediate(),
  authModal: {
    linkType: 'sign-in',
    setLinkType: () => {},
    isModalVisible: false,
    setIsModalVisible: () => {},
    showModal: { fn: (e: MouseEvent, type: string) => {} },
    showSuccessModal: true,
    setShowSuccessModal: () => {},
    showCustomPlanEnquirySuccess: false,
    setShowCustomPlanEnquirySuccess: () => {},
  },
  subscriptionModal: {
    isOpen: false,
    setIsOpen: () => {},
    currentStep: 0,
    setCurrentStep: () => {},
    isUpgrading: false,
    setIsUpgrading: () => {},
  },
  connectedAccounts: initialConnectedAccounts,
  setConnectedAccounts: () => {},
  isVariantConfigVisible: false,
  setIsVariantConfigVisible: () => {},
  populateAccounts: () => {},
  getAdAccountOptions: () => {},
  duplicateAsync: () => {},
  uploadProgress: {},
  setUploadProgress: () => {},
  columnConfigs: [],
  setColumnConfigs: () => {},
  activeColumnConfig: '',
  setActiveColumnConfig: () => {},
  metrics: {},
  setMetrics: () => {},
  allMetrics: [],
  setAllMetrics: () => {},
  defaultColumns: '',
  setDefaultColumns: () => {},
  duplicateJobsQueue: [],
  setDuplicateJobsQueue: () => {},
  snackbarOpened: false,
  setSnackbarOpened: () => {},
  setYScrollbarPosition: () => {},
  setDataGridBodyHeight: () => {},
  isSelectSubModalOpen: false,
  setSelectSubModalOpen: () => {},
  userPlan: {},
  setUserPlan: () => {},
  isPlansFetching: false,
  setPlansFetching: () => {},
  subscriptionPlans: [],
  setSubscriptionPlans: () => {},
  isFetchingCreditCard: false,
  setFetchingCreditCard: () => {},
  userCard: {},
  setUserCard: () => {},
  updateUser: () => {},
  userMaxCreditLimit: 0,
  setUserMaxCreditLimit: () => {},
  userMinCreditLimit: 0,
  setUserMinCreditLimit: () => {},
  pricingPerHundred: 0,
  setPricingPerHundred: () => {},
  trialCreditLimit: 0,
  setTrialCreditLimit: () => {},
  isPopulatingAccounts: false,
  setPopulatingAccounts: () => {},
  hasProductTourActive: false,
  setHasProductTourActive: () => {},
  productTourStep: 0,
  setProductTourStep: () => {},
  shouldRefreshCampaignList: false,
  setShouldRefreshCampaignList: () => {},
  isLiteUser: () => false,
  isEnterpriseUser: () => false,
  getPurchaseEvent: () => {},
  displayJustPublishedModal: false,
  setDisplayJustPublishedModal: () => {},
  isExhaustedCreditModalOpen: false,
  setExhaustedCreditModalOpen: () => {},
  isTopupCreditModalOpen: false,
  setTopupCreditModalOpen: () => {},
  showPaymentFailureModal: false,
  setShowPaymentFailureModal: () => {},
  numOfAIResponses: {
    image: 0,
    primaryText: 0,
    headlineText: 0,
    overlayText: 0
  },
  setNumOfAIResponses: () => {},
  isUserFree: () : boolean => { return false },
  isUserLite: () : boolean => { return false },
  isUserEnterprise: () : boolean => { return false },
  isUserInternal: () : boolean => { return false },
};

const AppContext = createContext<IAppContext>(defaultContext);

const AppContextProvider = AppContext.Provider;

const AppContextConsumer = AppContext.Consumer;

export const useAppContext = (): IAppContext => {
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState('');
  const userJson = localStorage.getItem('userInfo');
  const [user, setUser] = useState<User | null>(userJson === null ? userJson : JSON.parse(userJson));
  const [linkType, setLinkType] = useState('sign-in');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVariantConfigVisible, setIsVariantConfigVisible] = useState(false);
  const [displayJustPublishedModal, setDisplayJustPublishedModal] = useState(false);
  const showModal = useState({fn: (e: MouseEvent, type: string) => {
    if (e) {
      e.preventDefault();
    }
    /* if (type === 'sign-up') {
      // disable signup temporarily and show contact us
      const contactUsSection = document.querySelector('#contact-us');
      if (contactUsSection) {
        contactUsSection.scrollIntoView({
          behavior: 'smooth',
        });
      } else {
        window.location.href = '/#contact-us';
      }
    } else { */
      setIsModalVisible(true);
      setLinkType(type);
    // }
  }});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCustomPlanEnquirySuccess, setShowCustomPlanEnquirySuccess] = useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState<IAdAccountsInfo>(initialConnectedAccounts);
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [columnConfigs, setColumnConfigs] = useState<any>([]);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const oneHour = 1000 * 60 * 60 * 1;
  const twoMinutes = 1000 * 60 * 2;
  const interval = process.env.NODE_ENV === 'production' ? oneHour : twoMinutes;
  const [activeColumnConfig, setActiveColumnConfig] = useState<string>('');
  const [metrics, setMetrics] = useState({});
  const [allMetrics, setAllMetrics] = useState<any>([]);
  const [defaultColumns, setDefaultColumns] = useState('');
  const [duplicateJobsQueue, setDuplicateJobsQueue] = useState<any>([]);
  const [snackbarOpened, setSnackbarOpened] = useState(false);
  const [isSelectSubModalOpen, setSelectSubModalOpen] = useState<boolean>(false);
  const [userPlan, setUserPlan] = useState<any>({});
  const [isPlansFetching, setPlansFetching] = useState<boolean>(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState<any>([]);
  const [isFetchingCreditCard, setFetchingCreditCard] = useState<boolean>(false);
  const [userCard, setUserCard] = useState<any>({});
  const [userMaxCreditLimit, setUserMaxCreditLimit] = useState(0);
  const [userMinCreditLimit, setUserMinCreditLimit] = useState(0);
  const [pricingPerHundred, setPricingPerHundred] = useState(0);
  const [trialCreditLimit, setTrialCreditLimit] = useState(0);
  const [isPopulatingAccounts, setPopulatingAccounts] = useState<boolean>(false);
  const [hasProductTourActive, setHasProductTourActive] = useState<boolean>(false);
  const [productTourStep, setProductTourStep] = useState<number>(0);
  const [shouldRefreshCampaignList, setShouldRefreshCampaignList] = useState<boolean>(false);
  const [isExhaustedCreditModalOpen, setExhaustedCreditModalOpen] = useState<boolean>(false);
  const [isTopupCreditModalOpen, setTopupCreditModalOpen] = useState<boolean>(false);
  const [showPaymentFailureModal, setShowPaymentFailureModal] = useState<boolean>(false);
  const [numOfAIResponses, setNumOfAIResponses] = useState<INumOfAIGenerationResponses>({image: 0, primaryText: 0, headlineText: 0, overlayText: 0});
  const numOfAIResponsesFetched = useRef<boolean>(false);

  const fetchUnAuthUserData = async () => {
    if ( !(sessionStorage.getItem('isUnAuthLoggedIn') || null) ) {
      await loginUnregisteredUser();
      sessionStorage.setItem('isUnAuthLoggedIn', 'true');
      // sessionStorage.removeItem('default_template_id');
      // localStorage.removeItem('unauth_usage_stats');
    }

    let defaultTemplateId = sessionStorage.getItem('default_template_id') || 'no_data';
    if (defaultTemplateId === 'no_data') {
      let data = await getInfraParam('DEFAULT_SELECTED_TEMPLATE_ID');
      if (data?.data?.parameterValue) {
        sessionStorage.setItem('default_template_id', data?.data?.parameterValue);
      }
    }

    if (numOfAIResponses.image === 0) {
      axios.post('/api/user/infraParamsByKeys ', 
        {
          keys: [
            'NUM_OF_IMAGE_RESPONSE', 
            'AI_TEXT_SUGGESTION_COUNT_OVERLAY_TEXT'
          ]
        }).then(response => {
          setNumOfAIResponses({
            image: parseInt(response.data['NUM_OF_IMAGE_RESPONSE']),
            overlayText: parseInt(response.data['AI_TEXT_SUGGESTION_COUNT_OVERLAY_TEXT']),
            primaryText: 0,
            headlineText: 0,
          });
        }).catch(err => {
          console.error(err);
        });
    }
  }

  useEffect(() => {
    if (!loggedIn()) {
      fetchUnAuthUserData(); 
    }
  }, []);

  useEffect(() => {
    if (user) {
      getUserPlan()
        .then((response) => {
          // response.data.subscriptionInfo.subscriptionStatus = 'CLOSED';
          setUserPlan(response?.data || {});
        })
        .catch((err) => {
          console.log('err', err)
        });

      setPlansFetching(true);
      getPlansByGroup(user?.productGroupId)
        .then((response) => {
          var plans = response?.data?.plans;
          setSubscriptionPlans(plans || []);
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => {
          setPlansFetching(false);
        });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setFetchingCreditCard(true);
      getCardDetails()
        .then((response) => {
          if (typeof(response) === 'string' && response === 'Error') {
            setUserCard('no_card');
          } 
          else {
            setUserCard(response);
          }
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => {
          setFetchingCreditCard(false);
        });
    }
  }, [user]);

  useEffect(() => {
    // adds condition to redirection, sets interval only in case of logged in user.
    if (userJson) {
      setInterval(() => {
        checkUnsubscription(setUser);
      }, interval);
    }
  }, []);

  useEffect(() => {
    const setUserCreditLimits = async () => {
      try {
        if (user?.premiumTier === 100) {
          let userMaxCreditLimit = 0;
          user.userCreditRespDtoList?.forEach(credit => userMaxCreditLimit += credit.total);

          setUserMinCreditLimit(userMaxCreditLimit);
          setUserMaxCreditLimit(userMaxCreditLimit);
        }
        else if (user?.premiumTier === 9000) {
          let aiCreditLimits = await axios.get('/api/user/infraParam/SUBSCRIPTION_AI_CREDIT_LIMITS');
          setTrialCreditLimit(JSON.parse(aiCreditLimits.data?.parameterValue || "{}").ENTERPRISE.trial);
          setUserMaxCreditLimit(999999);
        }
        else if (user?.premiumTier === 9999) {
          let aiCreditLimits = await axios.get('/api/user/infraParam/INTERNAL_USER_CREDIT_LIMIT');
          setUserMaxCreditLimit(parseInt(aiCreditLimits.data?.parameterValue || 0, 10));
        }
        else if (user) {
          let userMaxCreditLimit = 0;
          user.userCreditRespDtoList?.forEach(credit => userMaxCreditLimit += credit.total);

          setUserMinCreditLimit(0);
          setUserMaxCreditLimit(userMaxCreditLimit);
        }
      } catch (e) {
        console.error(e);
      }
    }

    const setUserPricingPerHunder = async () => {
      if (user?.premiumTier === 100 || user?.premiumTier === 10) {
        try {
          let aiCreditLimits = await axios.get('/api/user/infraParam/CREDIT_PRICING_PER_HUNDRED');
          setPricingPerHundred(parseInt(aiCreditLimits.data?.parameterValue || 0, 10));
        } catch (e) {
          console.error(e);
        }
      }
    }

    if (user && [9999, 9000, 100, 10].includes(user.premiumTier)) {
      setUserCreditLimits();
      setUserPricingPerHunder();
    }
  }, [user]);

  const userCheckRegistered = useRef(false);
  useEffect(() => {
    const fetchRefreshPeriod = async () => {
      try {
        let periodResponse = await axios.get('/api/user/infraParam/USER_REFRESH_PERIOD');
        // Get refresh period from api or set as 6 hours in seconds (21600)
        let refreshPeriod = periodResponse?.data ? parseInt(periodResponse.data?.parameterValue) : 21600;

        // Check is need to sync with api on user object in every 10 seconds
        setInterval(async () => {
          let lsData = localStorage.getItem('userInfo') || 'no_data';
          if (lsData !== 'no_data') {
            let userFromLS = JSON.parse(lsData); 

            let localLastUpdate = new Date(userFromLS.localLastUpdate);

            let diffInMs = new Date().getTime() - localLastUpdate.getTime();
            let diffInSeconds = diffInMs / 1000;

            if (diffInSeconds > refreshPeriod) {
              try {
                const { data } = await Axios.get('/api/user/fetch');
                data.localLastUpdate = new Date().getTime();
                localStorage.setItem('userInfo', JSON.stringify(data));
                window.gaSetProps(data.abTestMap);
                setUser(data);
              } catch (e) {
                console.error(e);
              }
            }
          }
        }, 10000);
      } catch (e) {
        console.error(e);
      }
    }

    // if user changes, register event
    if (user && !userCheckRegistered.current) {
      userCheckRegistered.current = true;
      fetchRefreshPeriod();
    }

    if (!user) {
      userCheckRegistered.current = false;
    }
  }, [user]);

  useEffect(() => {
    if (user && !numOfAIResponsesFetched.current) {
      numOfAIResponsesFetched.current = true;
      axios.post('/api/user/infraParamsByKeys ', 
        {
          keys: [
            'NUM_OF_IMAGE_RESPONSE', 
            'AI_TEXT_SUGGESTION_COUNT_PRIMARY_TEXT', 
            'AI_TEXT_SUGGESTION_COUNT_HEADLINE', 
            'AI_TEXT_SUGGESTION_COUNT_OVERLAY_TEXT'
          ]
        }).then(response => {
          setNumOfAIResponses({
            image: parseInt(response.data['NUM_OF_IMAGE_RESPONSE']),
            primaryText: parseInt(response.data['AI_TEXT_SUGGESTION_COUNT_PRIMARY_TEXT']),
            headlineText: parseInt(response.data['AI_TEXT_SUGGESTION_COUNT_HEADLINE']),
            overlayText: parseInt(response.data['AI_TEXT_SUGGESTION_COUNT_OVERLAY_TEXT']),
          });
        }).catch(err => {
          console.error(err);
        });
    }
  }, [user]);

  const checkUnsubscription = async (setUser: Function) => {
    // todo test this change
    refreshUser(undefined, (user: User) => {
      window.dispatchEvent(new CustomEvent('subscriptionEvent', {
        bubbles: false,
        detail: { status: user.isPremiumUser },
      }));
    });
  };

  const trackPurchaseEvent = async () => {
    // informs the server of a purchase event in case if any purchase data was stored previously
    const purchaseData = SessionStorage.get('purchaseEventData');
    if (purchaseData) {
      await sendFbPixelToServer(purchaseData);
      SessionStorage.remove('purchaseEventData');
    }
  };

  const refreshUser = async(user?: User, success?: Function, errorFn?: Function) => {
    if (user === null) {
      localStorage.removeItem("userInfo");
      localStorage.removeItem('isFirstLogIn');
      localStorage.removeItem('admin_email');
      localStorage.removeItem('filter_selection');
      localStorage.removeItem('multiple_segment_selection');
      localStorage.removeItem('column_configuration_campaign');
      localStorage.removeItem('column_configuration_analytics');
      localStorage.removeItem('last_campaign_creation_configs');
      localStorage.removeItem('transferredMedia');
      //added this fix remove connected account data after logout
      setConnectedAccounts(initialConnectedAccounts);
      setUser(null);
      if (success) {
        await success(user);
      }
      return user;
    }
    if (user) {
      user.localLastUpdate = new Date().getTime();
      localStorage.setItem('userInfo', JSON.stringify(user));
      window.gaSetProps(user.abTestMap);
      setUser(user);
      if (success) {
        await success(user);
      }
      await trackPurchaseEvent();
      return user;
    }
    try {
      const { data } = await Axios.get('/api/user/fetch');
      data.localLastUpdate = new Date().getTime();
      localStorage.setItem('userInfo', JSON.stringify(data));
      window.gaSetProps(data.abTestMap);
      setUser(data);
      if (success) success(data);
      return data;
    } catch(error) {
      console.log('errorFn:', errorFn);
      if (errorFn) errorFn(error);
      console.error('couldn\'t get user info\n', error);
    }
  };

  const isTeamUser = (): boolean => {
    const x = user && user.teams && user.teams.length > 0 ? true : false;
    return x;
  };

  const updateUser = async (totalUsedCredits=0) => {
    if (!user) {
      return;
    }

    try {
      const { data } = await Axios.get('/api/user/fetch');
      data.localLastUpdate = new Date().getTime();
      localStorage.setItem('userInfo', JSON.stringify(data));
      window.gaSetProps(data.abTestMap);
      setUser(data);
    }
    catch (e) {
      console.error(e);
    }
  }

  /**
   * Populates connected accounts to app context
   */
   const populateAccounts = async () => {
    try {
      setPopulatingAccounts(true);
      const data = await getLoggedInData();
      setPopulatingAccounts(false);
      if (data && data.data) {
        setConnectedAccounts({...data.data, isLoading: false });
      }
    } catch (e) {
      setPopulatingAccounts(false);
      console.error(e);
    }
  };

  /**
   * Gives list of options for ad account selection from different platforms
   */
  const getAdAccountOptions = () => {
    const accounts = Object.keys(connectedAccounts.credentialsMap)
      .map(p => (
        connectedAccounts.credentialsMap[p.toUpperCase()].accounts
          .filter((acc: SocialAccountInfo) => acc.enabled === true && p != "ADJUST")
          .map((acc: SocialAccountInfo) => ({
            value: `${p}-${acc.id}`,
            label: `${utils.capitalizeFirstLetter(p)} - ${acc.name}`,
          }))
      ));
    return accounts.reduce((c, acc) => [...c, ...acc], []).sort((p1,p2)=> (p1.value > p2.value)?1:-1);
  };


  const duplicateAsync = async(url : string) => {
    try {
      const result = await Axios.get(url);
      if(result.data){
        let requestUrl = `/api/campaigns/duplicate/status/${result.data}`
        const status = await pollCampaignStatus(requestUrl);
        if(status.data){
          setDuplicateJobsQueue(curr => ([...curr,status.data]));
        }
      }  
    } catch (e) { 
      console.error('couldn\'t fetch  copy info from toolbar\n', e);
    }
  };

  const setYScrollbarPosition = () => {
    try{
      let headerHeight = document.getElementsByClassName('data-grid-head-container')[0].clientHeight;
      let footerHeight = document.getElementsByClassName('data-grid-footer-container')[0].clientHeight;
      let yScrollContainer = document.getElementsByClassName('scrollbar-container y')[0] as HTMLElement | null;
      if(yScrollContainer){
        yScrollContainer.style.top = String(headerHeight + 'px');
        // 100% - head - foot - scrollbarX 
        yScrollContainer.style.height = `calc(100% - ${headerHeight}px - ${footerHeight}px - 12px)`;
      }
    }
    catch (e) {
      console.warn('Error while setting scrollbar position.')
    } 
  }

  const setDataGridBodyHeight = () => {
    const headerElement = document.getElementsByClassName('data-grid-head-container')[0];
    if (headerElement) {
      const headerHeight = headerElement.clientHeight;

      const newHeight = `calc(100vh - ${356-(50-headerHeight)}px)`;

      const dataGridContainer = document.getElementsByClassName('data-grid-container')[0] as HTMLElement | null;
      if (dataGridContainer && newHeight.length > 0) {
        dataGridContainer.style.height = newHeight;
      }
    }
  }

  const isLiteUser = (): boolean => {
    if (user?.premiumTier === 100 || user?.premiumTier === 10) {
      return true;
    }
    return false;
  };

  const isEnterpriseUser = (): boolean => {
    if (user?.permissions.includes(Permissions.SUPER_USER)) {
      return true;
    }

    if (user?.premiumTier === 100 || user?.premiumTier === 10) {
      return false;
    }

    return true;
  };

  const getPurchaseEvent = () => {
    const params = new URLSearchParams(window.location.search);
    const purchaseData = {
      fbp: Storage.get('_fbp',true)|| params.get('fbp') || '',
      fbc: Storage.get('_fbc',true)|| params.get('fbc') || '',
      ipAddress: params.get('client_ip_address') || '',
      userAgent: navigator.userAgent || params.get('client_user_agent')||'',
    }
    if(constants.env() === 'QA' || (!utils.isEmpty(purchaseData.fbc) && !utils.isEmpty(purchaseData.fbp))) {
      SessionStorage.set('purchaseEventData', purchaseData);
    }
  };

  const isUserFree = () => {
    return user?.premiumTier === 10;
  }

  const isUserLite = () => {
    return user?.premiumTier === 100;  
  }

  const isUserEnterprise = () => {
    return user?.premiumTier === 1000;
  }

  const isUserInternal = () => {
    return user?.premiumTier === 9999;
  }

  return {
    currentPlayingVideo,
    setCurrentPlayingVideo,
    user,
    isTeamUser,
    setUser,
    refreshUser,
    isUserLoggedIn: loggedIn(),
    userHasCMSAccess: hasCMSAccess(),
    isUserSuperAdmin : isUserSuperAdmin(),
    isUserIntermediate : isUserIntermediate(),

    authModal: {
      linkType, setLinkType, isModalVisible, setIsModalVisible, showModal,
      showSuccessModal, setShowSuccessModal,
      showCustomPlanEnquirySuccess, setShowCustomPlanEnquirySuccess,
    },
    subscriptionModal: {
      isOpen, setIsOpen,
      currentStep, setCurrentStep,
      isUpgrading, setIsUpgrading,
    },
    connectedAccounts, setConnectedAccounts,
    isVariantConfigVisible, setIsVariantConfigVisible,
    populateAccounts,
    getAdAccountOptions,
    duplicateAsync,
    uploadProgress,
    setUploadProgress,
    columnConfigs,
    setColumnConfigs,
    activeColumnConfig,
    setActiveColumnConfig,
    metrics,
    setMetrics,
    allMetrics,
    setAllMetrics,
    defaultColumns,
    setDefaultColumns,
    duplicateJobsQueue,
    setDuplicateJobsQueue,
    snackbarOpened,
    setSnackbarOpened,
    setYScrollbarPosition,
    setDataGridBodyHeight,
    isSelectSubModalOpen,
    setSelectSubModalOpen,
    userPlan,
    setUserPlan,
    isPlansFetching,
    setPlansFetching,
    subscriptionPlans,
    setSubscriptionPlans,
    isFetchingCreditCard,
    setFetchingCreditCard,
    userCard,
    setUserCard,
    updateUser,
    userMaxCreditLimit,
    setUserMaxCreditLimit,
    userMinCreditLimit,
    setUserMinCreditLimit,
    pricingPerHundred,
    setPricingPerHundred,
    trialCreditLimit,
    setTrialCreditLimit,
    isPopulatingAccounts,
    setPopulatingAccounts,
    hasProductTourActive,
    setHasProductTourActive,
    productTourStep,
    setProductTourStep,
    shouldRefreshCampaignList,
    setShouldRefreshCampaignList,
    isLiteUser,
    isEnterpriseUser,
    getPurchaseEvent,
    displayJustPublishedModal,
    setDisplayJustPublishedModal,
    isExhaustedCreditModalOpen,
    setExhaustedCreditModalOpen,
    isTopupCreditModalOpen,
    setTopupCreditModalOpen,
    showPaymentFailureModal,
    setShowPaymentFailureModal,
    numOfAIResponses,
    setNumOfAIResponses,
    isUserFree,
    isUserLite,
    isUserEnterprise,
    isUserInternal,
  };

};

export {AppContext, AppContextProvider, AppContextConsumer};

import React, { FC } from 'react';
import { IBlogPost } from './Posts';
import Routes from '../../../common/routes';
import { useNavigate } from 'react-router-dom';

export interface IRenderPosts {
  posts: IBlogPost[];
  className?: string;
}

const RenderPosts: FC<IRenderPosts> = ({
  posts,
  className
}) => {

  const navigate = useNavigate();

  const openBlog = (post: IBlogPost) => {
    navigate(Routes.BLOG_POSTS.replace(':slug', post.slug));
  };

  return (
    <div className={`posts ${className || ''}`}>
      {posts?.map(post => (
        <div
          className="post"
          key={`post-${post.slug}`}
          onClick={() => openBlog(post)}
        >
          <div
            className="post-image"
            style={{ backgroundImage: `url(${post.image}` }}></div>
          {/* <img src={post.image} /> */}
          <div className="sub-head">{post.subHead}</div>
          <div className="title">{post.title}</div>
          <div className="blog-details">
            <div className="author">
              {post.author} | {post.date}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
};

export default RenderPosts;

import React, {useContext, useEffect, useState} from "react";
import { AppContext } from "../../appContext";
import { InlineLoader, Modal } from "frontend-core";
import axios from "axios";

const TopupCreditsModal = () => {
  const { 
    isTopupCreditModalOpen, 
    setTopupCreditModalOpen, 
    updateUser
  } = useContext(AppContext);

  const [state, _] = useState(window.location.href.includes('subscriptions') ? 'buy-extra' : 'insufficent');
  const [plansFetching, setPlansFetching] = useState<boolean>(false);
  const [plans, setPlans] = useState<any[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>({plan_id: -1});
  const [purchaseInProgress, setPurchaseInProgress] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [expirationTimeAsYear, setExpirationTimeAsYear] = useState<any>(2);

  useEffect(() => {
    if (plans.length === 0 && isTopupCreditModalOpen) {
      setPlansFetching(true);
      axios.get('/api/subscriptions/topUpCreditPlans')
        .then((response) => {
          setPlans(response.data);
          if (response.data.length === 3) {
            setSelectedPlan(response.data[1]);
          }
          else {
            setSelectedPlan(response.data[0]);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setPlansFetching(false);
        });

      axios.get('/api/user/infraParam/EXPIRY_TIME_TOPUP_CREDITS_MINUTES')
        .then((response) => {
          if (response.data) {
            var years = parseInt(response.data.parameterValue) / 525600;
            if (Number.isInteger(years)) {
              setExpirationTimeAsYear(years);
            }
            else {
              setExpirationTimeAsYear(years.toFixed(2));
            }
          }
          console.log(response.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [isTopupCreditModalOpen]);

  const handlePurchase = () => {
    setPurchaseInProgress(true);
    axios.post('/api/user/subscriptions/addTopUpCredits', {topUpPlanId: selectedPlan.plan_id})
      .then((response) => {
        setShowSuccess(true);
        updateUser();
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setPurchaseInProgress(false);
      });
  }

  const handleContinue = () => {
    setTopupCreditModalOpen(false);
    setShowSuccess(false);
  }

  const selectPlanBody = (
    <div className="exhausted-credit-modal">
      <div className="img-container">
        <img src="https://media.useblitz.com/assets/images/credit-card.svg" />
      </div>
      <div className="body-text-container">
        {
          state === 'insufficent' ?
            `You've run out of credits! Don't worry, buy now and keep creating amazin AI-powered ads!`
            :
            `Take your ad creation to the next level with additional credits. Buy now and keep creating amazing AI-powered ads!`
        }
      </div>
      <div className="body-plan-container">
        {
          plansFetching ? <InlineLoader isLoading={true} /> :
            plans.map((plan, index) => 
              <div 
                className={`plan-card ${selectedPlan.plan_id === plan.plan_id ? 'active-plan-card' : ''}`} 
                key={index}
                onClick={() => setSelectedPlan(plan)}
              >
                <div className="price">${plan.value}</div>
                <div className="credits">
                  <span className="title">{plan.credits}</span>
                  <span className='muted-text'> Credits</span>
                </div>
              </div> 
            )
        }
      </div>
      <div className="validation-msg">
        Credits are valid for {expirationTimeAsYear} years. 100 credits create around 10 AI-powered ads.
      </div>
      <div className="footer-container">
        {
          purchaseInProgress ? <InlineLoader isLoading={true} /> :
            <button className="btn" onClick={handlePurchase}> Buy Credits </button>
        }
      </div>
    </div>
  );

  const successBody = (
    <div className="exhausted-credit-modal">
      <div className="img-container">
        <img src="https://media.useblitz.com/assets/images/credit-card-success.svg" />
      </div>
      <div className="body-text-container">
        Congratulations! Your credits purchase was successful. You now have added {selectedPlan.credits} credits to fuel your AI-powered ad creation. Unleash your creativity and create stunning ads!
      </div>
      <div className="footer-container">
        <button className="btn" onClick={handleContinue}>Continue using our service</button>
      </div>
    </div>
  );

  const getTitle = () => {
    if (showSuccess) {
      return 'Payment Success';
    }
    if (state === 'insufficent') {
      return 'Insufficient Credits';
    }
    else {
      return 'Buy Additional Credits';
    }
  }

  return (
    <Modal
      show={isTopupCreditModalOpen}
      setShow={setTopupCreditModalOpen}
      title={getTitle()}
      onClose={() => setShowSuccess(false)}
    >
      {
        showSuccess ? successBody : selectPlanBody
      }
    </Modal>
  )
};

export default TopupCreditsModal;
import { SessionStorage, VideoPlayer } from 'frontend-core';
import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../../appContext';
import Authenticate from '../../../Authenticate';

import { LandingContext } from '../../LandingContext';

import LandingLayout from '../../Layout';
import constants from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import MetaTags from '../../../../common/MetaTags';
import Routes from '../../../../common/routes';

const TextToAd: React.FC = () => {
  const navigate = useNavigate();
  const [aiText, setAiText] = useState<string>('');

  const { authModal } = useContext(AppContext);

  const predefinedTexts = [
    'travel and tour agencies showcasing tours',
    'Elegant shades for the living room in a big house',
    'well-fitted black suits for men to be worn over a light coloured shirt',
    'beautiful coffee shop in the valley overlooking greenery'
  ];

  const handleSubmit = (e) => {
    if (aiText.length === 0) {
      return;
    }

    navigate('/ai-ads', {state: {type: 'textToAd', data: aiText}});
  }


  return (
    <LandingLayout>
      <>
        <MetaTags
          title="Text-to-Ads: Create Stunning Ad Creatives with text prompt using AI-Powered Technology"
          description="Transform your ideas into visually captivating ad creatives with AI-powered text-to-ads. Effortlessly generate engaging ads from simple text descriptions. No design skills required. Start creating text-based ads that stand out and drive results."
        />
        <div className="home landing-v2">

          <section className="peach-container editor-container-top">
            <div className="pad-80">
              <div className="layout-container">
                <div className="flex direction-column aic">
                  <h2 className="orange center">Create Ads with AI</h2>
                  <h2 className="center mw-970">Transform Text Descriptions into Stunning Visuals</h2>
                  <h5 className="mt-30 mb-30 mw-720 center">Experience the power of AI technology to create captivating ads from simple text descriptions. Generate visually appealing ads effortlessly and without any design skills. Start creating ads with AI using text inputs now!</h5>
                </div>
              </div>
            </div>
          </section>

          <section className="editor-container-bottom">
            <div className="pad-80">
              <div className="layout-container">
                <div className="editor-container">
                  <div className="editor">
                    <textarea
                      value={aiText}
                      onChange={e => setAiText(e.target.value)}
                      placeholder="Please describe the background image you want for your ad creative."
                    ></textarea>
                    <button
                      className="btn v3"
                      onClick={handleSubmit}
                    >
                      Create AI Ad
                    </button>
                    <div className="or-text">- Or try with an example</div>
                    <ul className="pre-defined-options">
                      {predefinedTexts.map((t, i) => (
                        <li onClick={() => setAiText(t)} key={`predefined-text-${t}`}>{t}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="flex flex-container key-points-container mt-100">
                  <div className="left no-flex">
                    <div className="content">
                      <h3>Unleash the Power of AI for Text Ad Creation</h3>
                      <h4>Effortlessly Create Stunning Text Ads with AI Technology</h4>
                    </div>
                  </div>
                  <div className="right">
                    <ul className="numbered-bullet-points">
                      <li>
                        <div className="pic">01</div>
                        <div className="text">Experience the future of ad creation with our AI-powered platform.</div>
                      </li>
                      <li>
                        <div className="pic">02</div>
                        <div className="text">Unlock the potential of AI to transform your text descriptions into visually captivating ads.</div>
                      </li>
                      <li>
                        <div className="pic">03</div>
                        <div className="text">With just a few simple steps, you can create professional-quality text ads that grab attention and drive results.</div>
                      </li>
                      <li>
                        <div className="pic">04</div>
                        <div className="text">Say goodbye to design limitations and hello to limitless possibilities with AI-generated text ads.</div>
                      </li>
                      <li>
                        <div className="pic">05</div>
                        <div className="text">Get ready to take your ad campaigns to the next level with the power of AI technology.</div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="flex aic jcc pad-80">
                  <div className="center mw-670">
                    <h3>
                      Creating Text Ads with AI:
                      <br />
                      A Simple Step-by-Step Process
                    </h3>
                    <h4 className="mt-20">Follow this step-by-step guide to create text ads effortlessly using our AI-powered platform.</h4>
                  </div>
                </div>
                <ul className="orange-key-points mb-20">
                  <li>
                    <div className="number">01</div>
                    <div className="text">Input your text description of the image OR product/service into the user-friendly interface.</div>
                  </li>
                  <li>
                    <div className="number">02</div>
                    <div className="text">Let our AI algorithms analyze your input and generate visually appealing ad variations.</div>
                  </li>
                  <li>
                    <div className="number">03</div>
                    <div className="text">Customize the generated ads by selecting text overlays and brand images etc.</div>
                  </li>
                  <li>
                    <div className="number">04</div>
                    <div className="text">Preview the ad variations and choose the ones that best match your campaign objectives.</div>
                  </li>
                  <li>
                    <div className="number">05</div>
                    <div className="text">Download the finalized text ads in various formats for seamless integration into your advertising campaigns.</div>
                  </li>
                </ul>
                <h5 className="center">With this easy-to-follow process, you can create captivating text ads in minutes, saving valuable time and resources.</h5>
              </div>
            </div>
          </section>

          <section className="peach-container optimize-container-top">
            <div className="inner-container pad-80">
              <h3>
                Maximizing Impact:
                <br />
                Best Practices for Text Ad Creation
              </h3>
              <h4>Optimize Your Text Ads for Maximum Engagement and Conversions</h4>
            </div>
          </section>
          <section className="optimize-container-bottom">
            <div className="inner-container pad-80">
              <div className="optimize-container">
                <h5 className="dark-blue">Follow these best practices to ensure your text ads stand out and drive results.</h5>
                <ul className="numbered-grid-list mt-40">
                  <li>
                    <div className="number">01</div>
                    <div className="text">Craft concise and compelling text descriptions that convey your key message effectively.</div>
                  </li>
                  <li>
                    <div className="number">02</div>
                    <div className="text">Incorporate relevant keywords and phrases to enhance the visibility of your static ads.</div>
                  </li>
                  <li>
                    <div className="number">03</div>
                    <div className="text">Experiment with different font styles, sizes, and color combinations to create visually appealing ads.</div>
                  </li>
                  <li>
                    <div className="number">04</div>
                    <div className="text">Use attention-grabbing headlines and strong calls-to-action (CTAs) to encourage user engagement.</div>
                  </li>
                  <li>
                    <div className="number">05</div>
                    <div className="text">Regularly test and iterate your text ads to optimize their performance and maximize conversions.</div>
                  </li>
                  <li>
                    <div className="number">06</div>
                    <div className="text">Leverage the data-driven insights provided by our AI algorithms to refine and improve your text ads over time.</div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="gallery-container">
            <div className="layout-container">
              <h3>
                Inspiring Examples of
                <br />
                AI-Generated Ads
              </h3>
              <h4>Explore the Possibilities: AI-Powered Ads That Captivate</h4>
              <img className="collage mt-80" src={`${constants.imagesUrl}landing/photo-collage-1.png`} alt="" />
              <img className="collage" src={`${constants.imagesUrl}landing/photo-collage-2.png`} alt="" />
            </div>
          </section>

          <section className="white-container">
            <div className="pad-80">
              <div className="layout-container">
                <div className="flex flex-container">
                  <div className="left">
                    <div className="content">
                      <h3>Unlock More Possibilities with Our Full Suite of AI-Powered Ad Solutions</h3>
                      <h4>Discover the Power of AI: Sign Up for <br />Complete Access</h4>
                      <button
                        className="btn v3"
                        onClick={(e) => authModal.showModal[0].fn(e, 'sign-in')}
                      >
                        Start with AI Ads
                      </button>
                    </div>
                  </div>
                  <div className="right">
                    <div className="sub-hero-image">
                      <img
                        src={`${constants.imagesUrl}landing/ai-powered-solutions-1.png`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </LandingLayout>
  );
};

export default TextToAd;

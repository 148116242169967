import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../appContext';

import LandingLayout from '../../Layout';
import constants from '../../../../constants';
import predefinedImages from './predefinedImages';
import { useNavigate } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import MetaTags from '../../../../common/MetaTags';
import Routes from '../../../../common/routes';

const ResizeAd: React.FC = () => {
  const navigate = useNavigate();
  const [aiImage, setAiImage] = useState<string>('');
  const [fileDragging, setFileDragging] = useState<boolean>(false);
  const fileTypes = ["JPEG", "PNG", "JPG"];

  const { authModal } = useContext(AppContext);

  const handleSubmit = () => {
    if (!aiImage) {
      return;
    }

    navigate('/ai-ads', { state: { type: 'resizeAd', data: aiImage }});
  }

  const activateUpload = () => {
    document.getElementById(`ai-image_label`)?.click();
  };

  const handleFileChange = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setAiImage(reader.result as string);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  return (
    <LandingLayout>
      <>
        <MetaTags
          title="Image-to-Ads: Turn Images into Eye-Catching Visuals with AI Technology"
          description="Leverage the power of AI-driven image-to-ads to transform your visuals into compelling ad creatives. Enhance and customize images effortlessly. Create captivating ads that resonate with your audience and boost your marketing campaigns."
        />
        <div className="home landing-v2">

          <section className="peach-container editor-container-top">
            <div className="pad-80">
              <div className="layout-container">
                <div className="flex direction-column aic">
                  <h2 className="orange center">Resize Ad Creative with AI</h2>
                  <h2 className="center mw-970">Seamless Ad Creative Resizing with AI for different Platforms</h2>
                  <h5 className="mt-30 mb-30 mw-720 center">Optimize your ad reach with AI-powered creative resizing. Easily transform a single ad creative into multiple optimized sizes for various social media platforms. Enhance engagement and visibility, all in a few clicks.</h5>
                </div>
              </div>
            </div>
          </section>

          <section className="editor-container-bottom">
            <div className="pad-80">
              <div className="layout-container">
                <div className="editor-container">
                  <div className="editor">
                    <FileUploader
                      classes={`upload-area ${!fileDragging ? 'upload-area-default-border' : ''}`}
                      multiple={false}
                      handleChange={handleFileChange}
                      name='imgToAdFile'
                      types={fileTypes}
                      hoverTitle={' '}
                      onDraggingStateChange={(dragging) => { setFileDragging(dragging); }}
                    >
                      {aiImage && <img src={aiImage} width={200} />}
                      <div className='flex ai-center jc-center gap-10'>
                        <img src='https://media.useblitz.com/assets/images/upload-icon-without-border.svg' width={20} />
                        <span> Click or drop a file here to start </span>
                      </div>
                    </FileUploader>
                    <button
                      className="btn v3"
                      onClick={handleSubmit}
                    >
                      Resize Ads
                    </button>
                    <div className="or-text">- Or try with an example</div>
                    <ul className="pre-defined-options images">
                      {predefinedImages.map((image, i) => (
                        <li onClick={() => setAiImage(image)} key={`predefined-image-${i}`} className="prompt-image">
                          <div className="image" style={{ backgroundImage: `url(${image})` }}></div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="flex flex-container key-points-container mt-100">
                  <div className="left no-flex">
                    <div className="content">
                      <h3>Effortless Ad Creative Resizing: Optimize Your Ads for Every Social Media Platform</h3>
                      <h4>Unlock the Power of AI for Ad Creative Resizing</h4>
                    </div>
                  </div>
                  <div className="right">
                    <ul className="numbered-bullet-points">
                      <li>
                        <div className="pic">01</div>
                        <div className="text">Experience the convenience of AI-driven ad creative resizing for social media platforms.</div>
                      </li>
                      <li>
                        <div className="pic">02</div>
                        <div className="text">No more worries about ad dimensions. Our AI technology generates optimized ad sizes for Facebook, Instagram, TikTok, Snapchat, Twitter, and more.</div>
                      </li>
                      <li>
                        <div className="pic">03</div>
                        <div className="text">Easily adapt your ad creatives to fit the specifications of each platform, ensuring maximum visibility and engagement.</div>
                      </li>
                      <li>
                        <div className="pic">04</div>
                        <div className="text">Say goodbye to manual resizing and hello to seamless, efficient ad creative adaptation with AI.</div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="flex aic jcc pad-80">
                  <div className="center mw-670">
                    <h3>
                      Resizing Ads Made Simple:
                      <br />
                      A Step-by-Step Process
                    </h3>
                    <h4 className="mt-20">Effortlessly Generate Multiple Ad Sizes Using AI Technology</h4>
                  </div>
                </div>
                <h5 className="center mb-20">
                  Follow this step-by-step guide to resize your ad creatives with AI-powered efficiency.
                </h5>
                <ul className="orange-key-points mb-20">
                  <li>
                    <div className="number">01</div>
                    <div className="text">Upload your ad creative or select an existing one from your library.</div>
                  </li>
                  <li>
                    <div className="number">02</div>
                    <div className="text">Let our AI algorithms analyze the image and generate optimized ad sizes for various social media platforms.</div>
                  </li>
                  <li>
                    <div className="number">03</div>
                    <div className="text">Customize the generated ad creatives to ensure they align with your brand and messaging.</div>
                  </li>
                  <li>
                    <div className="number">04</div>
                    <div className="text">Preview the resized ad variations and select the ones that best suit each platform's requirements.</div>
                  </li>
                  <li>
                    <div className="number">05</div>
                    <div className="text">Download the finalized ad creatives in different sizes, ready to boost your reach and engagement.</div>
                  </li>
                </ul>
                 <h5 className="center">With our user-friendly process and AI technology, you can create professional-grade image ads in a matter of minutes.</h5>
              </div>
            </div>
          </section>

          <section className="peach-container optimize-container-top">
            <div className="inner-container pad-80">
              <h3>
                Maximizing Impact:
                <br />
                Best Practices for Ad Creative Resizing
              </h3>
              <h4>Optimize Your Ad Creatives for Every Social Media Platform</h4>
            </div>
          </section>
          <section className="optimize-container-bottom">
            <div className="inner-container pad-80">
              <div className="optimize-container">
                <h5 className="dark-blue">Implement these best practices to ensure your ad creatives shine on all social media platforms.</h5>
                <ul className="numbered-grid-list five-columns mt-40">
                  <li>
                    <div className="number">01</div>
                    <div className="text">Begin with high-resolution images that maintain their quality across different sizes.</div>
                  </li>
                  <li>
                    <div className="number">02</div>
                    <div className="text">Pay attention to focal points and ensure the essential elements remain visible in each resized version.</div>
                  </li>
                  <li>
                    <div className="number">03</div>
                    <div className="text">Customize the ad copy and CTAs to match the context and audience of each platform.</div>
                  </li>
                  <li>
                    <div className="number">04</div>
                    <div className="text">Test the resized ad variations to gauge their effectiveness before launching your campaigns.</div>
                  </li>
                  <li>
                    <div className="number">05</div>
                    <div className="text">Leverage the power of AI insights to refine your ad creatives and improve their performance.</div>
                  </li>
                </ul>
              </div>
              <h5 className="mt-20">By following these best practices, you can create image ads that stand out, engage your audience, and drive conversions.</h5>
            </div>
          </section>

          <section className="gallery-container">
            <div className="layout-container">
              <h3>
                Inspiring Examples of
                <br />
                AI-Generated Ads
              </h3>
              <h4>Explore the Possibilities: AI-Powered Ads That Captivate</h4>
              <img className="collage mt-80" src={`${constants.imagesUrl}landing/photo-collage-3.png`} alt="" />
              <img className="collage" src={`${constants.imagesUrl}landing/photo-collage-4.png`} alt="" />
            </div>
          </section>

          <section className="white-container">
            <div className="pad-80">
              <div className="layout-container">
                <div className="flex flex-container">
                  <div className="left">
                    <div className="content">
                      <h3>Unlock More Possibilities with Our Full Suite of AI-Powered Ad Solutions</h3>
                      <h4>Discover the Power of AI: Sign Up for <br />Complete Access</h4>
                      <button
                        className="btn v3"
                        onClick={(e) => authModal.showModal[0].fn(e, 'sign-in')}
                      >
                        Start with AI Ads
                      </button>
                    </div>
                  </div>
                  <div className="right">
                    <div className="sub-hero-image">
                      <img
                        src={`${constants.imagesUrl}landing/ai-powered-solutions-2.png`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </LandingLayout>
  );
};

export default ResizeAd;

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { LI_TRACKING_IDS, TrackLIEvent } from '../../common/LinkedInEventTracker';
import Routes from '../../common/routes';
import ContactModal from './ContactModal';
import { LandingContext } from './LandingContext';
import { ICustomWindow } from '../../common/model';
import Events from "../creativeLanding/Events";
import axios from 'axios';
import { Modal } from 'frontend-core';
import { GaTracker } from '../../common/GaTracker';

declare const window: ICustomWindow;
// const captchaKey = process.env.NODE_ENV === 'production';
const captchaKey = window.location.hostname === 'www.smartblitz.ai' ?
  '6LcvM6MZAAAAAIAMVpKaBNE_fa0IcX78dAD8HwJm' :
  '6LcuZqUZAAAAAOYv3eobTn_1_zwn6Au-GDOASCN6';

const LandingFooter: React.FC = () => {
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    showContactModal,
    setShowContactModal
  } = useContext(LandingContext);

  const handleEmailSubmit = () => {
    setLoading(true);
    let contactForm = {
      adBudget: '0',
      company: email,
      email: email,
      message: email,
      name: email,
    }
    window.grecaptcha.ready(function() {
      try {
        window.grecaptcha.execute(captchaKey, {action: 'submit'})
        .then(function(token: any) {
          const data = JSON.parse(JSON.stringify(contactForm));
          Events.track('Lead', {
            email: data.email || "",
          });
          data.token = token;
          data.queryParamStr = localStorage.getItem("SIGNUPPARAMS") || '';
          const endpoint = '/api/contactus';
          axios
            .post(endpoint, data)
            .then(response => {
              setEmail('');
              setShowSuccessModal(true);
              setLoading(false)
              window.gaTrack("request_email_subscribe" ,{
                email: data.email || ""
              });
            })
            .catch(error => {
              setLoading(false);
              console.error(error);
            });

        })
        .catch((e: any) => {
          console.error('grecaptcha then then', e);
        });
      } catch (e) {
        setLoading(false);
        console.error('grecaptcha e', e);
      }
    });
  }

  return (
    <div className="landing-v2">
      <div className="landing-footer">
        <div className="blue-container">
          <div className="layout-container pad-60 blue-container-content">
            <div className="top">
              <div className="footer-left">
                <Link to={Routes.HOME}>
                  <div className="logo orange-white"></div>
                </Link>
                <div className="social-links">
                  <a className="icon facebook" href='https://www.facebook.com/blitzgrowthinc' target='_blank' rel="noreferrer" />
                  <a className="icon twitter" href='https://twitter.com/blitz_growth' target='_blank' rel="noreferrer" />
                  <a className="icon linkedin" href='https://www.linkedin.com/company/useblitz/' target='_blank' rel="noreferrer" />
                  <a className="icon youtube" href='https://www.youtube.com/@blitzgrowth' target='_blank' rel="noreferrer" />
                  <a className="icon instagram" href='https://www.instagram.com/blitz.growth/' target='_blank' rel="noreferrer" />
                </div>
              </div>
              <div className="footer-right">
                <ul className="footer-stacked-links">
                  <li className="head">Company</li>
                  {/* <li>
                    <Link to={Routes.ABOUT}>About Us</Link>
                  </li> */}
                  <li>
                    <Link to={Routes.FAQS}>FAQs</Link>
                  </li>
                  <li>
                    <Link to={Routes.BLOG}>Blogs</Link>
                  </li>
                </ul>

                {/* <ul className="footer-stacked-links">
                  <li className="head">Explore</li>
                  <li>
                    <Link to={Routes.ABOUT}>Company</Link>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowContactModal(true);
                      }}
                    >Pricing</a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowContactModal(true);
                      }}
                    >Contact us</a>
                  </li>
                </ul> */}

                {/* <ul className="footer-stacked-links">
                  <li className="head">Products</li>
                  <li>
                    <Link to={Routes.TEXT_TO_AD}>Text to Ad</Link>
                  </li>
                  <li>
                    <Link to={Routes.IMAGE_TO_AD}>Image to Ad</Link>
                  </li>
                </ul> */}

                <ul className="footer-stacked-links">
                  <li className="head">Supported Platforms</li>
                  <li>
                    <a href="//facebook.com" target="_blank" rel="noreferrer">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="//instagram.com" target="_blank" rel="noreferrer">
                      Instagram
                    </a></li>
                  <li>
                    <a href="//twitter.com" target="_blank" rel="noreferrer">
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a href="//tiktok.com" target="_blank" rel="noreferrer">
                      TikTok
                    </a>
                  </li>
                  <li>
                    <a href="//snapchat.com" target="_blank" rel="noreferrer">
                      Snapchat
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bottom">
              <div className="layout-container flex footer-separator">
                <div className="left copy-text">
                &copy; Copyright 2022 Blitz.com |  All rights reserved.
                </div>
                <div className="right">
                  <ul className="footer-links">
                    <li>
                      <Link to={Routes.TERMS_OF_SERVICE}>
                        Terms and Condtions
                      </Link>
                    </li>
                    <li>
                      <Link to={Routes.PRIVACY_POLICY}>
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="top">
          <div className="circle one"></div>
          <div className="circle two"></div>
          <div className="layout-container flex">
            <div className="title">Take your business to the next level.</div>
            <button
              data-ga-event="click"
              data-ga-data={'Contact Us'}
              data-ga-action="ShowContactModal"
              type="button"
              className="btn v2 bright large"
              onClick={() => {
                TrackLIEvent(LI_TRACKING_IDS.contactUs);
                setShowContactModal(true);
                window['contactOpenSource'] = 'HomePage::Bottom Bar:Contact Us';
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
         */}
        <ContactModal show={showContactModal} setShow={setShowContactModal} />
      </div>
      <Modal
        show={showSuccessModal}
        showClose={true}
        setShow={setShowSuccessModal}
        className="landing-modal"
      >
        <div className='mt-10 text-center'>
          Thank you for subscribing. You'll now receive our latest insights and offers straight to your inbox.
        </div>
      </Modal>
    </div>
  );
};

export default LandingFooter;

import Axios from "axios";

export async function getSubscriptionInfo() {
  const url = '/api/user/subscription/info';
  try {
    const { data } = await Axios.get(url);
    
    return data;
  } catch (e) {
    console.error('getSubscriptionInfo error\n', e);
  }
}

export async function getCardDetails() {
  try {
    const { data } = await Axios.get('/api/user/card');
    return data;
  } catch(e) {
    console.error('card details GET failed\n', e);
  }
}

export async function getTeamMembers() {
  try {
    const { data } = await Axios.get('/api/user/team/all');
    return data;
  } catch(e) {
    console.error('team members GET failed\n', e);
  }
  return null;
}

export const getLoggedInData = async () => {
  try {
    const accounts = await Axios.get('/api/user/manage/getLoggedInData');
    return accounts;
  } catch (e) {
    console.error('Error while calling get logged in data\n', e);
  }
};

export const switchToUserAccount = async (userName: string) => {
  try {
    const data = await Axios.get('/api/admin/switchUser', { params: { userName }});
    return data;
  } catch (e) {
    throw e;
  }
};

export const switchToAdminAccount = async () => {
  try {
    const data = await Axios.get('/api/switchToAdminMode');
    return data;
  } catch (e) {
    throw e;
  }
};

export const logUserOut = async () => {
  try {
    const data = await Axios.get('/api/user/logout');
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gets the active subscription plans
 * @returns - axios response
 */
export const getSubscriptionPlans = async () => {
  try {
    const data = await Axios.get('/api/subscriptions/plans');
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Updates the provided email address
 * @param email {string} - email address that needs to be updated
 * @returns - axios response object
 */
export const updateEmail = async (email) => {
  try {
    const data = await Axios.post('/api/user/updateEmail', { email });
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Updates the provided email address
 * @param email {string} - email address that needs to be updated
 * @returns - axios response object
 */
export const upgradeSubscription = async (planId) => {
  if (!planId) {
    throw ('No plan selected!');
  }
  try {
    const data = await Axios.post('/api/subscription/upgrade', { planId });
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Updates the provided email address
 * @param email {string} - email address that needs to be updated
 * @returns - axios response object
 */
 export const updateCard = async (payload) => {
  try {
    const data = await Axios.post('/api/user/updateCard', payload);
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Checks the verification code validity
 * 
 */
 export const verifyCode = async (payload) => {
  try {
    const data = await Axios.post('/api/user/verifyCode', payload);
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Sends the verification code
 * 
 */
 export const resendCode = async (payload) => {
  try {
    const data = await Axios.post('/api/user/sendVerificationMail', payload);
    return data;
  } catch (e) {
    throw e;
  }
};
/**
 * Checks the verification code validity for email update
 * 
 */
 export const verifyEmailUpdateCode = async (payload) => {
  try {
    const data = await Axios.post('/api/user/verifyEmailUpdateCode', payload);
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gets token for the unauthenticated users
 * 
 */
export const loginUnregisteredUser = async () => {
  try {
    const data = await Axios.post('/api/user/loginUnregisteredUser');
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Gets token for the google users
 * 
 */
export const loginOrRegisterWithGoogle = async (payload) => {
  return Axios.post('/api/user/loginOrRegisterWithGoogle', payload);
};

/**
 * Gets token for the facebook users
 * 
 */
export const loginOrRegisterWithFacebook = async (payload) => {
  return Axios.post('/api/user/loginOrRegisterWithFacebook', payload);
};
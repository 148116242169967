import axios from 'axios';

/**
 * Gets the user saved campaign column configurations
 * @returns Object
 */
 export const getAccountDropdownOptions = async () => {
  try {
    const data = await axios.get('/api/user/infraParam/adminDropdownData');
    return data;
  } catch (e) {
    throw ('Error while getting the account dropdown options!');
  }
};

/**
 * Fetches all dashboard Metrics
 * @returns axios object
 */
 export const getAllUserDashboardMetrics = async () => {
  try {
    const data = await axios.get(`/api/adAccount/getAllUserDashboardMetrics`);
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * Fetches Infra params with given params
 * @returns axios object
 */
export const getInfraParam = async (paramName) => {
  try {
    const data = await axios.get(`/api/user/infraParam/${paramName}`);
    return data;
  } catch (e) {
    throw e;
  }
};

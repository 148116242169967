import HomeFaqs from './HomeFaqs';

const Faqs = [
  ...HomeFaqs,
  {
    id: 9,
    question: "How can AI technology improve the effectiveness of my Facebook and Instagram ads?",
    answer: "Discover the power of AI technology in optimizing your Facebook and Instagram ads. Our AI algorithms analyze audience preferences and behaviours, enabling you to create highly targeted and engaging ads that maximize conversions and drive results."
  },
  {
    id: 10,
    question: "Can I use my own images in the AI-powered ad creation process?",
    answer: "Absolutely! Our platform allows you to upload your own images to create ads with a personal touch. Whether it's product photos, brand imagery, or any other visuals, our AI technology enhances and transforms them into captivating ad creatives."
  },
  {
    id: 11,
    question: "Is it possible to customize the AI-generated ad variations?",
    answer: "Yes, customization is key! Our AI-generated ad variations serve as a starting point, and you have complete control to customize them. Adjust colours, text, overlays, and other design elements to align with your branding and campaign objectives."
  },
  {
    id: 12,
    question: "What makes our AI-powered ad creation different from traditional ad design tools?",
    answer: "Our AI-powered ad creation goes beyond traditional design tools by utilizing advanced algorithms. With just a text description or an image input, our AI technology generates multiple ad variations, saving you time and effort while delivering professional-quality results."
  },
  {
    id: 13,
    question: "Can I download the AI-generated ad creatives in different sizes for various social media platforms?",
    answer: "Absolutely! Our platform offers the convenience of downloading AI-generated ad creatives in sizes optimized for various social media platforms, including Facebook, Instagram, TikTok, Snapchat, Twitter, and more. Maximize your reach with ads tailored to each platform's specifications."
  },
  {
    id: 14,
    question: "How many ad campaigns can I publish on Facebook and Instagram for free?",
    answer: "As part of our free plan, you can publish one ad campaign on Facebook and Instagram. To publish additional campaigns, an upgrade to our premium plans is required, unlocking more opportunities and features."
  },
  {
    id: 15,
    question: "Are there any limitations to the number of ad creatives I can create using the AI-powered platform?",
    answer: "Our platform offers fixed credits for creating ad creatives. Once you exhaust your credits, you'll be prompted to upgrade to a premium plan to continue creating more ad variations and unlock additional features for enhanced creativity."
  }
];

export default Faqs;

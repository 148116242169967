import { Dimension } from "../render/inputs/Dimension";

export async function getImageDimension(url:string): Promise<Dimension> {
  const img = document.createElement("img");
  img.crossOrigin="anonymous";
  img.src = url;
  await img.decode();  
  let dimension:Dimension = new Dimension();
  dimension.width = img.naturalWidth;
  dimension.height = img.naturalHeight;
  return dimension;
}


export async function getImageElem(url:string) {
  const img:HTMLImageElement = document.createElement("img");
  img.crossOrigin="anonymous";
  img.src = url;
  await img.decode();
  return img;
}



export async function getDataUrl(url:string):Promise<string> {
  const img:HTMLImageElement = document.createElement("img");
  img.crossOrigin="anonymous";
  img.src = url;
  await img.decode();
  let canvas:HTMLCanvasElement = document.createElement("canvas");
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;
  let ctx = canvas.getContext("2d");
  ctx?.drawImage(img, 0, 0);
  return canvas.toDataURL();
}

import { SessionStorage, VideoPlayer } from 'frontend-core';
import React, { useContext, useState, useEffect, ChangeEvent } from 'react';
import { AppContext } from '../../../../appContext';

import LandingLayout from '../../Layout';
import constants from '../../../../constants';
import predefinedImages from './predefinedImages';
import { useNavigate } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import MetaTags from '../../../../common/MetaTags';
import Routes from '../../../../common/routes';

const ImageToAd: React.FC = () => {
  const navigate = useNavigate();
  const [aiImage, setAiImage] = useState<string>('');
  const [fileDragging, setFileDragging] = useState<boolean>(false);
  const fileTypes = ["JPEG", "PNG", "JPG"];

  const { authModal } = useContext(AppContext);

  const handleSubmit = () => {
    if (!aiImage) {
      return;
    }

    navigate('/ai-ads', {state: {type: 'imageToAd', data: aiImage}});
  }

  const activateUpload = () => {
    document.getElementById(`ai-image_label`)?.click();
  };

  const handleFileChange = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setAiImage(reader.result as string);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  return (
    <LandingLayout>
      <>
        <MetaTags
          title="Image-to-Ads: Turn Images into Eye-Catching Visuals with AI Technology"
          description="Leverage the power of AI-driven image-to-ads to transform your visuals into compelling ad creatives. Enhance and customize images effortlessly. Create captivating ads that resonate with your audience and boost your marketing campaigns."
        />
        <div className="home landing-v2">

          <section className="peach-container editor-container-top">
            <div className="pad-80">
              <div className="layout-container">
                <div className="flex direction-column aic">
                  <h2 className="orange center">Create Stunning Ads with AI</h2>
                  <h2 className="center mw-970">Transform Images into Eye-Catching Visuals</h2>
                  <h5 className="mt-30 mb-30 mw-720 center">Experience the power of AI technology to create captivating ads from your images. Generate visually appealing ads effortlessly and without any design skills. Start creating ads with AI using image inputs now!</h5>
                </div>
              </div>
            </div>
          </section>

          <section className="editor-container-bottom">
            <div className="pad-80">
              <div className="layout-container">
                <div className="editor-container">
                  <div className="editor">
                    <FileUploader
                      classes={`upload-area ${!fileDragging ? 'upload-area-default-border' : ''}`}
                      multiple={false}
                      handleChange={handleFileChange}
                      name='imgToAdFile'
                      types={fileTypes}
                      hoverTitle={' '}
                      onDraggingStateChange={(dragging) => { setFileDragging(dragging); }}
                    >
                      {aiImage && <img src={aiImage} width={200} />}
                      <div className='flex ai-center jc-center gap-10'>
                        <img src='https://media.useblitz.com/assets/images/upload-icon-without-border.svg' width={20} />
                        <span> Click or drop a file here to start </span>
                      </div>
                    </FileUploader>
                    <button
                      className="btn v3"
                      onClick={handleSubmit}
                    >
                      Create AI Ad
                    </button>
                    <div className="or-text">- Or try with an example</div>
                    <ul className="pre-defined-options images">
                      {predefinedImages.map((image, i) => (
                        <li onClick={() => setAiImage(image)} key={`predefined-image-${i}`} className="prompt-image">
                          <div className="image" style={{ backgroundImage: `url(${image})` }}></div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="flex flex-container key-points-container mt-100">
                  <div className="left no-flex">
                    <div className="content">
                      <h3>Elevate Your Ads with AI: Transform Images into Stunning Visuals</h3>
                      <h4>Unleash the Power of AI for Image Ad Creation</h4>
                    </div>
                  </div>
                  <div className="right">
                    <ul className="numbered-bullet-points">
                      <li>
                        <div className="pic">01</div>
                        <div className="text">Experience the cutting-edge technology of AI that brings your images to life in stunning ads.</div>
                      </li>
                      <li>
                        <div className="pic">02</div>
                        <div className="text">Discover how our AI-powered platform can revolutionize your advertising efforts with visually captivating image ads.</div>
                      </li>
                      <li>
                        <div className="pic">03</div>
                        <div className="text">No design skills? No problem. Our AI technology empowers you to create professional-quality image ads effortlessly.</div>
                      </li>
                      <li>
                        <div className="pic">04</div>
                        <div className="text">Tap into the potential of AI and take your ad campaigns to new heights with captivating visuals that grab attention.</div>
                      </li>
                      <li>
                        <div className="pic">05</div>
                        <div className="text">Explore the future of ad creation and witness the transformation of your images into impactful advertisements.</div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="flex aic jcc pad-80">
                  <div className="center mw-670">
                    <h3>
                      Creating Compelling Image Ads:
                      <br />
                      A Step-by-Step Process
                    </h3>
                    <h4 className="mt-20">Generate Eye-Catching Image Ads Using AI in a Few Simple Steps</h4>
                  </div>
                </div>
                <h5 className="center mb-20">
                  Follow this step-by-step guide to create visually stunning image ads using our AI-powered platform.
                </h5>
                <ul className="orange-key-points mb-20">
                  <li>
                    <div className="number">01</div>
                    <div className="text">Input your text description of the image OR product/service into the user-friendly interface.</div>
                  </li>
                  <li>
                    <div className="number">02</div>
                    <div className="text">Let our AI algorithms analyze your input and generate visually appealing ad variations.</div>
                  </li>
                  <li>
                    <div className="number">03</div>
                    <div className="text">Customize the generated ads by selecting text overlays and brand images etc.</div>
                  </li>
                  <li>
                    <div className="number">04</div>
                    <div className="text">Preview the ad variations and choose the ones that best match your campaign objectives.</div>
                  </li>
                  <li>
                    <div className="number">05</div>
                    <div className="text">Download the finalized text ads in various formats for seamless integration into your advertising campaigns.</div>
                  </li>
                </ul>
                <h5 className="center">With our user-friendly process and AI technology, you can create professional-grade image ads in a matter of minutes.</h5>
              </div>
            </div>
          </section>

          <section className="peach-container optimize-container-top">
            <div className="inner-container pad-80">
              <h3>
                Maximizing Impact:
                <br />
                Best Practices for Image Ad Creation
              </h3>
              <h4>Optimize Your Image Ads for Maximum Engagement and Conversions</h4>
            </div>
          </section>
          <section className="optimize-container-bottom">
            <div className="inner-container pad-80">
              <div className="optimize-container">
                <h5 className="dark-blue">Implement these best practices to create image ads that captivate your audience and drive results.</h5>
                <ul className="numbered-grid-list mt-40">
                  <li>
                    <div className="number">01</div>
                    <div className="text">Start with high-quality images that align with your brand and effectively convey your message.</div>
                  </li>
                  <li>
                    <div className="number">02</div>
                    <div className="text">Experiment with AI-generated enhancements to enhance the visual appeal and impact of your image ads.</div>
                  </li>
                  <li>
                    <div className="number">03</div>
                    <div className="text">Incorporate strong and clear calls-to-action (CTAs) that prompt users to take the desired action.</div>
                  </li>
                  <li>
                    <div className="number">04</div>
                    <div className="text">Ensure your images and ad copy are relevant, compelling, and tailored to your target audience.</div>
                  </li>
                  <li>
                    <div className="number">05</div>
                    <div className="text">Test different layouts, color schemes, and design elements to find the combinations that resonate best.</div>
                  </li>
                  <li>
                    <div className="number">06</div>
                    <div className="text">Leverage the data-driven insights provided by our AI algorithms to refine and optimize your image ads over time.</div>
                  </li>
                </ul>
              </div>
              <h5 className="mt-20">By following these best practices, you can create image ads that stand out, engage your audience, and drive conversions.</h5>
            </div>
          </section>

          <section className="gallery-container">
            <div className="layout-container">
              <h3>
                Inspiring Examples of
                <br />
                AI-Generated Ads
              </h3>
              <h4>Explore the Possibilities: AI-Powered Ads That Captivate</h4>
              <img className="collage mt-80" src={`${constants.imagesUrl}landing/photo-collage-3.png`} alt="" />
              <img className="collage" src={`${constants.imagesUrl}landing/photo-collage-4.png`} alt="" />
            </div>
          </section>

          <section className="white-container">
            <div className="pad-80">
              <div className="layout-container">
                <div className="flex flex-container">
                  <div className="left">
                    <div className="content">
                      <h3>Unlock More Possibilities with Our Full Suite of AI-Powered Ad Solutions</h3>
                      <h4>Discover the Power of AI: Sign Up for <br />Complete Access</h4>
                      <button
                        className="btn v3"
                        onClick={(e) => authModal.showModal[0].fn(e, 'sign-in')}
                      >
                        Start with AI Ads
                      </button>
                    </div>
                  </div>
                  <div className="right">
                    <div className="sub-hero-image">
                      <img
                        src={`${constants.imagesUrl}landing/ai-powered-solutions-2.png`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </LandingLayout>
  );
};

export default ImageToAd;

import { FbApp,TikTokApp} from '../pages/manage/createAdsContext';

import Routes from './routes';
export interface ICustomWindow extends Window {
  reportSignupConversion: Function;
  reportContactUsConversion: Function;
  reportSubscription: Function;
  onUserChanged: Function;
  showSubscribeModal: Function;
  showPexelsModal: Function;
  onPexelSelectionCompleted: Function;
  showAdvancedConfigModal: (adsConfig: string, defaultConfig: string) => void;
  Dropbox: {
    createChooseButton : Function;
    choose: Function;
  };
  grecaptcha: any;
  enableArrowScrollControls: Function;
  disableArrowScrollControls: Function;
  enableTextEditing: Function;
  disableTextEditing: Function;
  willUpload: number;
  filterWaitingCounter: number;
}

export interface IUser {}

export interface IPageProps {
  loggedIn?: boolean;
  path?: string;
  user?: IUser;
  navigation?: any;
}

export interface FormFieldString {
  value: string;
  error: string;
  showHelp?: boolean;
  isFetching?: boolean;
  touched?: boolean;
  [key: string]: string | number | boolean | undefined;
}

export interface FormFieldNumber {
  value: number;
  error: string;
  showHelp?: boolean;
  isFetching?: boolean;
  touched?: boolean;
  [key: string]: string | number | boolean | undefined;
}

export interface FormFieldBoolean {
  value: boolean;
  error: string;
  showHelp?: boolean;
  isFetching?: boolean;
  touched?: boolean;
  [key: string]: string | boolean | undefined;
}

export interface FormFieldStringArray {
  value: string[];
  error: string;
  showHelp?: boolean;
  isFetching?: boolean;
  touched?: boolean;
  [key: string]: string | string[] | boolean | undefined;
}

export interface FormFieldGenericFieldArray {
  value: GenericField[];
  error: string;
  showHelp?: boolean;
  isFetching?: boolean;
  touched?: boolean;
  [key: string]: string | GenericField[] | boolean | undefined;
}

export interface FormFieldGenericField {
  value: GenericField;
  error: string;
  showHelp?: boolean;
  isFetching?: boolean;
  touched?: boolean;
  [key: string]: string | GenericField | boolean | undefined;
}

export interface IAd {
  id: string;
  adAccountId: string;
  name: string;
  platform: string;
  adSquadId: string;
  creativeId: string;
  renderType: string;
  reviewStatus: string;
  reviewStatusReasons: string[];
  status: string;
  title: string | null;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAdQueueObject {
  id?: string;
  isCreated?: boolean;
  names: string;
  selectedAdSetIndex: number;
  adType: string;
  appNames: string;
  appIcons: string;
  headline: string[];
  description: string[];
  ctaText: string[];
  iosEnabled: boolean;
  iosAppId: string;
  androidEnabled: boolean;
  androidPackageName: string;
  websiteEnabled?: boolean;
  websiteUrl?: string;
  media: IAdMedia[];
  carouselMedia : IFbcarouselAttachment[];
  //story ad params
  storyMedia : IScStoryAttachment[];
  storyPreviewMedia? : IScStoryPreviewAttachment;
  storyPlayBackType : string;
  //
  brandName: string;
  textCode: string;
  displayLink : string;
  adState : boolean;
  pageId?: GenericField;
  instaAppId?: GenericField;
}

export interface IAdMedia {
  adId: number;
  socialBatchMediaId: number;
  batchId: string;
  userId: number;
  mediaId: string;
  mediaName: string;
  mediaUrl: string;
  adAccountId: string;
  socialEndPoint: string;
  errorMessage : string;
  _meta: {
    // this object is not returned by server
    // this is used only by the ui
    selected: boolean;
    isUploading: boolean;
    percentUploaded: number;
  }
}

export interface IAdSet {
  id: string;
  adAccountId: string;
  name: string;
  startTime: string;
  platform: string;
  autoBid: string;
  bidMicro: string;
  bidStrategy: string;
  billingEvent: string;
  campaignId: string;
  dailyBudgetMicro: string;
  deliveryConstraint: string;
  optimizationGoal: string;
  pacingType: string;
  placement: string;
  placementV2: string | null;
  status: string;
  targetBid: string;
  targeting: any;  // todo add type
  targetingReachStatus: string;
  title?: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAdSetQueueObject {
  id?: string;
  type : string;
  isCreated?: boolean;
  name: string;
  pageId?: GenericField;
  fbAppId?: GenericField;
  instaAppId?: GenericField;
  tiktokAppId?: GenericField;
  operatingSystem: string;
  pixelEnabled: boolean;
  pixelId: string;
  iosVersion: string;
  androidVersion: string;
  budget: number;
  bidControl: number;
  optimisationGoal: string;
  minAge: string;
  maxAge: string;
  gender: string;
  country: string[];
  countryGroup :string[];
  language: string[];
  customDetailedTargeting: string;
  targetingTextCode : string;
  customText:string;
  customOptimisationGoalEvent: string;
  detailedTargeting: string[];
  customAudiences : string[];
  customAudiencesExclude : string[];
  savedAudience : GenericField;
  selectedFbApp? : FbApp;
  selectedTikTokApp? : TikTokApp;
  adAccountId?:string;
  adSetState : boolean;
  bidStrategy : string;
  adSetDynamicStatus :boolean;
}

export interface SavedAudience {
    id: string;
    name: string;
    minAge: string;
    maxAge: string ;
    customAudiences: string[];
    customAudiencesExclude : string[];
    countries: string[];
    languages: string[];
    gender: string;
    detailedTargeting: string[];
    customDetailedTargeting : string;
}

enum UserStatus {
  PENDING = 'PENDING',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum Permissions {
  CMS_ACCESS = 'CMS_ACCESS',
  DEFAULT = 'DEFAULT',
  UI_TOOL_ADVANCE = 'UI_TOOL_ADVANCE',
  TEAM_ADMIN = 'TEAM_ADMIN',
  UI_TOOL_INTERMEDIATE = 'UI_TOOL_INTERMEDIATE',
  SUPER_USER = 'SUPER_USER',
};

export enum PlayBack {
  AUTO_ADVANCING = 'AUTO_ADVANCING',
  LOOPING = 'LOOPING',
}

interface Team {
  Id: number;
  name: string;
}

export interface TeamMember {
  admin: boolean;
  email: string;
  pending: boolean;
}

export interface User {
  companyId: number;
  email: string | null;
  isEmailVerified: boolean;
  switchedFromAdminMode: boolean;
  isPremiumUser: boolean;
  premiumTier: number;
  abTestMap: any;
  permissions: Permissions[];
  status: UserStatus;
  userId: number;
  name?: string;
  lastName?: string;
  teams?: Team[];
  blitzAppId?: string;
  createdAt?: string;
  productGroupId?: string;
  localLastUpdate?: number;
  totalAiCreditsUsed?: number;
  freeCreditsExpiryTime?: number;
  isEnterpriseTrialUsed?: boolean;
  isEnterpriseTrial?: boolean;
  enterpriseTrialExpiryEpoch?: number;
  aiImageCreditsUsed?: number;
  aiTextCreditsUsed?: number;
  fueState?: string;
  userCreditRespDtoList?: any[];
}

export const staticPagePaths: string[] = [
  Routes['AGENCY'],
  Routes['STUDIO_PAGE'],
  Routes['RESET_PASSWORD'],
  Routes['TERMS_OF_SERVICE'],
  Routes['PRIVACY_POLICY']
];

export interface PlanAdditionalParams {
  title: string;
  subtitle: string;
  trialText: string;
  displayOrder: number;
  price?: number;
}

export type SubscriptionType = 'YEARLY' | 'QUARTERLY' | 'HALF_YEARLY' | 'BI_WEEKLY' | 'TRI_WEEKLY' | 'MONTHLY' | 'BI_MONTHLY' | 'WEEKLY' | 'DAILY' | 'BI_DAILY' | 'TRI_DAILY' | 'DISCRETE';
export type SubscriptionStatus = 'IN_TRIAL' | 'IN_DISCOUNT' | 'ACTIVE' | 'CANCEL' | 'PENDING' | 'CLOSED' | 'FORCE_CLOSED' | 'UNKNOWN' | 'ERROR';

export interface SubscriptionPlan {
  Id: number;
  subscriptionSource: string;
  sourcePlanId: string;
  extraCredits: number;
  finalPrice: number;
  finalId: number;
  subscriptionType: SubscriptionType;
  isActive: boolean;
  additionalParams: PlanAdditionalParams;
}

export interface CampaignForm {
  platform: FormFieldString;
  type: FormFieldString;
  name: FormFieldString;
  snapchatAccountId: FormFieldString;
  facebookAccountId: FormFieldString;
  tikTokAccountId: FormFieldString;
  objective: FormFieldString;
  budget: FormFieldNumber;
  bidStrategy: FormFieldString;
  appProfileStatus : FormFieldBoolean;
  [key: string]: FormFieldString | FormFieldNumber|FormFieldBoolean;
}

export interface AdSetForm {
  name: FormFieldString;
  type : FormFieldString;
  pageId: FormFieldGenericField;
  fbAppId: FormFieldGenericField;
  instaAppId: FormFieldGenericField;
  tiktokAppId : FormFieldGenericField;
  operatingSystem: FormFieldString;
  pixelEnabled: FormFieldBoolean;
  pixelId: FormFieldString;
  iosVersion: FormFieldString;
  androidVersion: FormFieldString;
  budget: FormFieldNumber;
  bidControl: FormFieldNumber;
  optimisationGoal: FormFieldString;
  minAge: FormFieldString;
  maxAge: FormFieldString;
  gender: FormFieldString;
  country: FormFieldStringArray;
  countryGroup : FormFieldStringArray;
  language: FormFieldStringArray;
  customDetailedTargeting: FormFieldString;
  targetingTextCode : FormFieldString;
  customText : FormFieldString;
  customOptimisationGoalEvent: FormFieldString;
  detailedTargeting: FormFieldGenericFieldArray;
  customAudiences : FormFieldGenericFieldArray;
  customAudiencesExclude : FormFieldGenericFieldArray;
  savedAudience : FormFieldGenericField;
  adSetState: FormFieldBoolean;
  bidStrategy : FormFieldString;
  adSetDynamicStatus : FormFieldBoolean;
  [key: string]: FormFieldString | FormFieldNumber | FormFieldBoolean | FormFieldStringArray
    | FormFieldGenericField|FormFieldGenericFieldArray;
}

export interface AdsForm {
  names: FormFieldString;
  selectedAdSetIndex: FormFieldString;
  adType: FormFieldString;
  headline: FormFieldStringArray;
  description: FormFieldStringArray;
  appNames: FormFieldString;
  appIcons: FormFieldString;
  ctaText: FormFieldStringArray;
  iosEnabled: FormFieldBoolean;
  iosAppId: FormFieldString;
  androidEnabled: FormFieldBoolean;
  androidPackageName: FormFieldString;
  websiteEnabled: FormFieldBoolean;
  websiteUrl: FormFieldString;
  brandName: FormFieldString;
  textCode: FormFieldString;
  displayLink : FormFieldString;
  adState: FormFieldBoolean;
  pageId: FormFieldGenericField;
  instaAppId: FormFieldGenericField;
  [key: string]: FormFieldString | FormFieldBoolean | FormFieldNumber|FormFieldStringArray|FormFieldGenericField;
}

export interface TableColumn {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  hidden?: boolean;
  format?: (record: any) => JSX.Element | string;
}

export type SocialEndpoints = 'SNAPCHAT' | 'FACEBOOK' | 'TIKTOK' | 'GOOGLE';
export type SocialEndpointsAll = 'SNAPCHAT' | 'FACEBOOK' | 'INSTAGRAM' | 'INSTA_STORY' | 'TIKTOK'| 'GOOGLE';

export enum PublishingPlatforms {
  SNAPCHAT = 'SNAPCHAT',
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  GOOGLE = 'GOOGLE'
  // SHOPIFY = 'SHOPIFY',
}

export interface SocialAccountInfo {
  id: string;
  name: string;
  enabled: boolean;
}

export interface GenericField {
  value: string;
  label: string;
}

export enum MutateMode {
  VIEW,
  CREATE,
  EDIT,
}

export interface ICredentialsMap {
  FACEBOOK?: IAdAccInfo;
  SNAPCHAT?: IAdAccInfo;
  ADJUST?: IAdAccInfo;
  // SHOPIFY?: {
  //   access_token: string;
  //   shop_name: string;
  // };
}

interface IAdAccInfo {
  access_token: string;
  accounts: SocialAccountInfo[];
  activeAccount: string;
}

export interface IAdAccountsInfo {
  credentialsMap: ICredentialsMap | {};
  maxVariants: number;
  unProcessedBatch: [];
  activePlatform : string;
  isLoading?: boolean;
}

export interface FileContainer {
  file?: File;
}
export interface FileContainerLogo {
  file?: File;
}

export type TemplateType =
  'HOST_STUDIO_CENTER_GRAPHICS'
  | 'HOST_STUDIO_CENTER_GRAPHICS_BUY_SINGLE'
  | 'HOST_STUDIO_CENTER_GRAPHICS_BUY_MULTI'
  | 'HOST_STUDIO_CENTER_GRAPHICS_SELL_SINGLE'
  | 'HOST_STUDIO_CENTER_GRAPHICS_SELL_MULTI'
  | 'HOST_STUDIO_TOP_POP'
  | 'HOST_STUDIO_TOP_POP_BUY_MULTI'
  | 'HOST_STUDIO_TOP_POP_SELL_MULTI'
  | 'HOST_STUDIO_WSTH'
  | 'HOST_STUDIO_WSTH_BUY_MULTI'
  | 'HOST_STUDIO_WSTH_SELL_MULTI'
  | 'HOST_STUDIO_PIP_GRAPHICS'
  | 'HOST_STUDIO_PIP_GRAPHICS_BUY'
  | 'HOST_STUDIO_PIP_GRAPHICS_SELL'
  | 'TESTIMONIAL_MEME'
  | 'TESTIMONIAL_MEME_BUY'
  | 'TESTIMONIAL_MEME_SELL'
  | 'TESTIMONIAL_INTRO_TEXT_CARD'
  | 'TESTIMONIAL_INTRO_TEXT_CARD_BUY'
  | 'TESTIMONIAL_INTRO_TEXT_SELL'
  | 'USER_TESTIMONIAL_MEME'
  | 'USER_TESTIMONIAL_MEME_BUY'
  | 'USER_TESTIMONIAL_MEME_SELL'
  | 'USER_TESTIMONIAL_INTRO_TEXT_CARD'
  | 'USER_TESTIMONIAL_INTRO_TEXT_CARD_BUY'
  | 'USER_TESTIMONIAL_INTRO_TEXT_CARD_SELL'
  | 'USER_TESTIMONIAL_STORY_NATIVE'
  | 'USER_TESTIMONIAL_STORY_NATIVE_BUY'
  | 'USER_TESTIMONIAL_STORY_NATIVE_SELL'
  | 'APP_TOUR'
  | 'APP_TOUR_BUY'
  | 'APP_TOUR_SELL'
  | 'ROOM_3D_TOUR'
  | 'ROOM_3D_TOUR_BUY_MULTI'
  | 'ROOM_3D_TOUR_SELL_MULTI'
  | 'STATIC_CENTER_GRAPHICS'
  | 'VIDEO_GENERIC'
  | 'STATIC_GENERIC'
  ;

export enum XhrStatus {
  ASLEEP = 'ASLEEP',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
};

  export interface VariantDropDown {
    variants: string[],
    variantIds: number[],
    selected: number
  }

  export interface VariantItemSection {
    variantTitle: string,
    variantBatchTitle: string,
    variantDropDown: VariantDropDown
  }

  export interface VariantSection {
    sectionTitle: string,
    sectionItems: VariantItemSection[]
  }

  export interface VariantConfiguration {
    configurations: VariantSection[]
  }

  export interface CampaignStatusModel {
     name: string,
     status :string,
     requestId: string,
     userId: string,
     error : any
  }

  export interface IFileProgress {
    fileName: string;
    percent: number;
  }

  export interface IFbcarouselAttachment {
    id: number;
    type : string;
    mediaId : string;
    mediaUrl : string;
    headline : string;
    link : string;
  }

  export interface IScStoryAttachment {
    id: number;
    type : string;
    mediaId : string;
    mediaUrl : string;
    headline : string;
    cta : string;
    appName : string;
    iosAppId : string;
    androidAppUrl : string;
    appIconUrl : string;
    websiteUrl : string;
  }

  export interface IScStoryPreviewAttachment {
    isAttached :  boolean;
    mediaUrl : string;
    headline : string;
    logoUrl :  string;
  }

  export interface LocationState {
    requestId 
  }

  export interface INumOfAIGenerationResponses {
    image: number;
    primaryText: number;
    headlineText: number;
    overlayText: number;
  }

import constants from "../../../../constants";

const predefinedImages = [
  `${constants.imagesUrl}landing/image-prompts/girl-with-roses.jpeg`,
  `${constants.imagesUrl}landing/image-prompts/flowers.jpeg`,
  `${constants.imagesUrl}landing/image-prompts/bungalow.jpeg`,
  `${constants.imagesUrl}landing/image-prompts/landscape.jpeg`,
];

export default predefinedImages;
4
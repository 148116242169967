import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface IMetaTags {
  title: string;
  description: string;
}

const MetaTags: FC<IMetaTags> = ({
  title,
  description
}) => {

  const image = 'https://media.useblitz.com/assets/images/icons/blitz-smart-orange-logo.svg';
  const url = `${window.location.protocol}://www.smartblitz.ai${window.location.pathname}`;

  return (
    <Helmet>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Twitter Card data */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* Twitter Summary card images must be at least 120x120px */}
      <meta name="twitter:image" content={image} />

      {/* Open Graph data */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="smartblitz.ai" />
      <meta name="p:domain_verify" content="18aa2f400ae0ab7ee8fd5ddebbec543f" />
    </Helmet>
  );
}

export default MetaTags;

import React, {useState, useRef, FormEvent, ChangeEvent, Fragment, useContext} from "react";
import axios from 'axios';
import { Modal, CustomCheckbox, Storage, SessionStorage, Loader } from "frontend-core";
import ForgotPassword from "./forgot-password";
import { ICustomWindow } from "../common/model";
import Routes from "../common/routes";
import { AppContext } from "../appContext";
import { useNavigate } from "react-router-dom";
import VerificationModal from "./VerificationModal";
import { verifyCode, resendCode, loginOrRegisterWithGoogle, loginOrRegisterWithFacebook } from "../../src/api/account-api";
import { LI_TRACKING_IDS, TrackLIEvent } from "../common/LinkedInEventTracker";
import { TrackFBPixel } from "../common/FBPixelTracker";
import { GoogleLogin } from "@react-oauth/google";
import { FacebookProvider, LoginButton } from 'react-facebook';
// import FacebookLogin from 'react-facebook-login';

declare const window: ICustomWindow;
interface IAuthProps {
  type?: string;
  updateProp: Function;
  setIsModalVisible: Function;
}

interface IAuthForm {
  userName: string,
  password: string,
  queryParamStr: string,
  [key: string]: string | undefined,
  userNameError?: string,
  passwordError?: string,
  nameError?: string
  // passwordStrength: string,  // todo
}

enum AuthModalMethods {
  LoginOrSignup = 'LOGIN_OR_SIGNUP',
  ForgotPW = 'FORGOT_PASSWORD',
}

const AuthenticateV2 = (props: IAuthProps) => {
  const { authModal, refreshUser } = useContext(AppContext);
  const { setIsModalVisible } = authModal;
  const [method, setMethod] = useState<AuthModalMethods>(AuthModalMethods.LoginOrSignup);
  const [error, setError] = useState("");
  const [submitTryOnce, setSubmitTryOnce] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [OTPError, setOTPError] = useState("");
  const [authForm, setAuthForm] = useState({
    userName: "",
    password: "",
    queryParamStr: "",
    userNameError: "",
    passwordError: "",
    nameError: ""
  });
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [codeRequestCount, setCodeRequestCount] = useState(0);
  const [sendingOTP, setSendingOTP] = useState(false);
  const [isFreePlanLoading, setFreePlanLoading] = useState(false);
  const isNewUserRef = useRef<boolean>(false);

  // const { login } = useLogin();

  const handleChange = (event: ChangeEvent<HTMLInputElement>, authForm: IAuthForm, setAuthForm: Function) => {
    authForm[event.target.name] = event.target.value;
    setAuthForm(authForm);
  }
  
  const isEmailValid = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  const isFormValid = (formInputs: IAuthForm, setAuthForm: Function) => {
    formInputs.userNameError = "";
    formInputs.passwordError = "";
    formInputs.nameError = "";
    formInputs.queryParamStr = "";
    if (!formInputs.userName) {
      formInputs.userNameError = "Email is required"
    } else if (formInputs.userName.length > 100) {
      formInputs.userNameError = "Email should be less than 50 characters"
    } else if (!isEmailValid(formInputs.userName)) {
      formInputs.userNameError = "Invalid email"
    }
    if (!formInputs.password) {
      formInputs.passwordError = "Password is required"
    } else if (formInputs.password.length < 6) {
      formInputs.passwordError = "Password should be at least 6 characters"
    } else if (formInputs.password.length > 30) {
      formInputs.passwordError = "Password should be less than 30 characters"
    }
    const newInput = JSON.parse(JSON.stringify(formInputs));
    setAuthForm(newInput);
    if (formInputs.userNameError || formInputs.passwordError || formInputs.nameError) {
      return false;
    }
    return true;
  }
  
  const action2Click = (method: AuthModalMethods, props: IAuthProps, setShowForgotPassword: Function) => {
    if(method === AuthModalMethods.LoginOrSignup) {
      setShowForgotPassword(false);
      setMethod(AuthModalMethods.LoginOrSignup);
      props.updateProp("sign-up");
    } else if (method === AuthModalMethods.ForgotPW) {
      setShowForgotPassword(true);
    } else {
      setShowForgotPassword(false);
      setMethod(AuthModalMethods.LoginOrSignup);
      props.updateProp("sign-in");
    }
  }
  
  function getModalStyle() {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    };
  }
  
  const inputStyles = { width: "100%", height: "40px !important", background: "e9f0f3", border: "none", borderRadius: 4 };

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleSubmit = (method: AuthModalMethods, event: FormEvent) => {
    event.preventDefault();

    setSubmitTryOnce(true);
    setRequestInProgress(true);
    if (!isFormValid(authForm, setAuthForm)) {
      setRequestInProgress(false);
      return;
    }

    let url = '/api/user/loginOrRegister';

    const data: IAuthForm = JSON.parse(JSON.stringify({...authForm, remember: rememberMe }));
    delete data.passwordError;
    delete data.userNameError;
    delete data.nameError;
    data.queryParamStr = localStorage.getItem("SIGNUPPARAMS") || '';
    setError("");

    axios
      .post(url, data)
      .then(response => {
        setCodeRequestCount(prevState => prevState + 1);
        if (!response.data.isEmailVerified) {
          setShowVerificationModal(true);
        }
        else {
          refreshUser(response.data);
          setIsModalVisible(false);
          setRequestInProgress(false);
        }

        if (response.data.isNewUser) {
          // means that user registered
          if (window.location.pathname === Routes.AI_ADS) {
            isNewUserRef.current = true;
          }

          TrackFBPixel('CompleteRegistration', {
            content_name: "Blitz SMART",
            currency: 'USD',
            status: "Signup",
            value: 0
          });
          TrackLIEvent(LI_TRACKING_IDS.createMyAccount);
        }
        else {
          // means that user signed in
          TrackLIEvent(LI_TRACKING_IDS.signIn);
        }

      })
      .catch(error => {
        let errorMsg = 'Something went wrong, we\'re sorry. Please try again after some time.';
        if (error && error.response && error.response.data && error.response.data.length < 100) {
          errorMsg = error.response.data;
        }
        setError(errorMsg);
        if(errorMsg === "E-mail verification pending.") setShowVerificationModal(true);
        setRequestInProgress(false);
      });
  };

  const handleOTPConfirmation = async(code) => {
    const res = await verifyCode({ email: authForm.userName, code });
    if(res.data.status === "ALREADY_VERIFIED" || res.data.status === "VERIFIED"){
      setIsModalVisible(false);
      setShowVerificationModal(false);

      if (isNewUserRef.current) {
        setFreePlanLoading(true);
        isNewUserRef.current = false;
        await axios.post('/api/user/upgrade/freePlan', {paymentDetailsAdded : false});
        await axios.post('/api/user/updateFue', {fueState: 'FUE_CREATION'});
        setFreePlanLoading(false);
      }

      
      refreshUser(undefined);
      // window.reportSignupConversion(afterSignUpOrLogin);
    } else if(res.data.status === "INVALID_CODE") {
      setOTPError("Please enter a valid code");
    }
  }

  const handleResendOTP = async () => {
    setSendingOTP(true);
    const data = await resendCode({ email: authForm.userName });
    if(data.data) {
      setSendingOTP(false);
    }
    setCodeRequestCount(prevState => prevState + 1);
  }

  const handleGoogleLogin = (googleResponse) => {
    loginOrRegisterWithGoogle({ code: googleResponse.credential, isOneTap: true })
      .then(async (response) => {
        refreshUser(response.data);

        if (response.data.isNewUser) {
          if (window.location.pathname === Routes.AI_ADS) {
            setFreePlanLoading(true);
            isNewUserRef.current = false;
            await axios.post('/api/user/upgrade/freePlan', {paymentDetailsAdded : false});
            await axios.post('/api/user/updateFue', {fueState: 'FUE_CREATION'});
            setFreePlanLoading(false);
          }
        }

        setIsModalVisible(false);
      })
      .catch((e) => {
        setError('Google Sign in Issue');
        console.error(e);
      });
  }

  const handleFacebookLogin = (response) => {
    loginOrRegisterWithFacebook({ accessToken: response.authResponse.accessToken, expiresIn: response.authResponse.expiresIn })
      .then(async (response) => {
        refreshUser(response.data);

        if (response.data.isNewUser) {
          if (window.location.pathname === Routes.AI_ADS) {
            await axios.post('/api/user/upgrade/freePlan', {paymentDetailsAdded : false});
            await axios.post('/api/user/updateFue', {fueState: 'FUE_CREATION'});
          }
        }

        setIsModalVisible(false);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const body = (
    <div className="login-popup">
      <div
        className="auth-modal-close close"
        onClick={() => {
          props.setIsModalVisible(false);
          SessionStorage.remove('userPlanSelection');
        }}
      >
      </div>
      {!showForgotPassword && <Fragment>
      <div className="heading-container">
        <span className="heading">Log in or Sign up</span>
      </div>
      <form 
        data-ga-event="submit"
        data-ga-action={method}
        noValidate
        onSubmit={(e) => handleSubmit(method, e)}>
        <div className="email-pw-row">
          <div className="form-control">
            <input 
              className="form-input" 
              type="email" 
              name="userName" 
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, authForm, setAuthForm)} 
              placeholder="Email"
              style={inputStyles}
              required
            />
          </div>
          <div className="form-control flex" style={{ gap: "9px" }}>
              <input 
              className="form-input" 
              type="password" 
              name="password" 
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, authForm, setAuthForm)}
              placeholder="Password"
              style={inputStyles}
              />
          </div>
        </div>
        <p style={{color:'red'}}>
          <small>{error}</small>
        </p>
        {submitTryOnce && authForm.nameError &&
          <p style={{color: 'red', marginTop: '-1rem'}}>
            <small>{authForm.nameError}</small>
          </p>}
        {submitTryOnce && authForm.userNameError &&
          <p style={{color: 'red', marginTop: '-1rem'}}>
            <small>{authForm.userNameError}</small>
          </p>}
        {submitTryOnce && authForm.passwordError &&
          <p style={{color: 'red', marginTop: '-1rem'}}>
            <small>{authForm.passwordError}</small>
          </p>}
        <button 
          data-ga-event="click"
          data-ga-data={JSON.stringify(
            Object.entries(authForm).reduce((acc, [key, value]) => 
              key !== "password" ? {...acc, [key]: value} : acc, 
              {}
            )
          )}
          data-ga-action={method}
          type="submit"
          disabled={requestInProgress}
          className={`btn full-width auth-btn`}
        >
          Sign In
        </button>
      </form>
      <div className="social-signin-container">
        <span className="or-text"> - Or sign in with - </span>
        <div className="d-flex-imp ai-center gap-10 social-signin-row"> 
          <div className="google-container">
            <GoogleLogin
              theme="filled_blue"
              size="large"
              logo_alignment="left"
              onSuccess={handleGoogleLogin}
              onError={() => {
                console.error('Google SignIn Failed');
              }}
            />
          </div>
          <div className="facebook-container">
            <LoginButton
              scope="email"
              onError={(e) => console.error('Facebook login error', e)}
              onSuccess={handleFacebookLogin}
            >
              <div className="facebook-btn">
                <div />
                Login with Facebook
              </div>
            </LoginButton>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <button
          className="forgot-pw"
          onClick={() => setShowForgotPassword(true)}
        >
          Forgot Password ?
        </button>
      </div>
      <div className="privacy">
        By signing in, you agree to our <a href="/terms-of-service" target={'_blank'}>Terms of service</a>, and <a href="/privacy-policy" target={'_blank'}> Privacy Policy.</a>
      </div>
      </Fragment>}
      {showForgotPassword &&
      <Fragment>
        <ForgotPassword />
        <div className="footer-row" style={{ textAlign: "center", paddingBottom: '20px' }}>
          <span onClick={() => action2Click(AuthModalMethods.LoginOrSignup, props, setShowForgotPassword)}
          >
            Return to Log In
          </span>
        </div>
      </Fragment>}
    </div>
  );

  return(
    <section>
      <Modal
        className="auth-modal"
        show={authModal.isModalVisible}
      >
        {body}
      </Modal>
      <VerificationModal 
        showVerificationModal={showVerificationModal} 
        setShowVerificationModal={setShowVerificationModal} 
        onOTPConfirm={handleOTPConfirmation} 
        onResendOTP={handleResendOTP} 
        OTPError={OTPError} 
        codeRequestCount={codeRequestCount} 
        sendingOTP={sendingOTP}
        onClose={() => setRequestInProgress(false)}
      />
      <Loader isLoading={isFreePlanLoading} />
    </section>
  );
}

export default AuthenticateV2;

import React from 'react';

import PostContentGeneration from "./PostContentGeneration";
import PostUserContentImportant from './PostUserContentImportant';
import PostPlatformsNft from './PostPlatformsNft';
import PostThreeCourseDinner from './PostThreeCourseDinner';
import PostRiseOfTikTok from './PostRiseOfTikTok';
import PostMentalHealth from './PostMentalHealth';
import PostMarketingMistakes from './PostMarketingMistakes';
import PostInfluencerMarketing from './PostInfluencerMarketing';
import PostABTesting from './PostABTesting';
import PostCreateWinningAd from './PostCreateWinningAd';


export const externalLink = (url, text, space = true) => {
  return (
    <>
      {space ? ' ' : ''}<a href={url} rel="noreferrer" target="_blank">{text}</a>
    </>
  );
};

export type IBlogPost = {
  slug: string;
  subHead: string;
  title: string;
  image: string;
  author: string;
  date: string;
  content: JSX.Element;
  authorImage?: string;
  description?: string;
};

const Posts: IBlogPost[] = [
  {
    slug: 'how-to-get-user-generated-content-for-paid-social',
    subHead: 'Creative',
    title: 'Content Generation 101: How to get User Generated Content for Paid Social',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/637bd45a4f019be4667ab97d_Content%20Generation%20101%20How%20to%20get%20User%20Generated%20Content%20for%20Paid%20Social-p-1080.png',
    author: 'Kaylie Mings',
    date: 'November 21, 2022',
    description: "The marketing field is a constantly evolving space; the tempo of change is quickening. We've seen this in recent years with the boom of TikTok and brands valuing more customer-centric marketing. This shift has given rise to a new trend: utilizing user-generated content (UGC) in paid social campaigns, where everyday people are sharing more authentic reactions to products...",
    content: <PostContentGeneration />
  },
  {
    slug: 'why-is-user-generated-content-so-important',
    subHead: 'Marketing Strategy',
    title: 'Why is user generated content so important?',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/636eae39adc7c1d0b6adaca2_2%20%281%29-p-1080.png',
    author: 'Kaylie Mings',
    date: 'November 12, 2022',
    description: "If you've ever seen an unboxing video, a clothing try-on haul, product reviews, or product tutorials on YouTube, TikTok, or Instagram, you were most likely watching user-generated content (UGC). UGC is content created by unpaid contributors to a platform, for educational or entertainment purposes...",
    content: <PostUserContentImportant />
  },
  {
    slug: 'ad-of-the-week-mental-health-matters',
    subHead: 'Ad of the Week',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/636ea85bad2b3030c75cf46f_JeffreyMarsh-p-1080.png',
    title: 'Ad of the Week: Mental Health Matters',
    author: 'Kaylie Mings',
    date: 'November 11, 2022',
    description: "For this latest Ad of the Week, we are highlighting an ad run on TikTok for Real, an app with therapist-designed mental health programs available on-demand. Real believes that mental health matters, and it is important to them that all marketing reflects their mission...",
    content: <PostMentalHealth />
  },
  {
    slug: 'best-community-building-platforms-for-nft-drops',
    subHead: 'web3',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/636aa851c5bb9c5d40067fb2_2-p-1080.png',
    title: 'Best Community Building Platforms for NFT Drops',
    author: 'Kaylie Mings',
    date: 'November 7, 2022',
    description: "If you're looking to market an NFT giveaway, you're going to want to use a performance marketing agency like Blitz to achieve your specific conversion goals through high quality ad creative deployed at scale, detailed performance tracking, and continuous campaign iteration. However, an NFT project launch is also about building your NFT community to incentivize ownership and ensure long-term growth of your project...",
    content: <PostPlatformsNft />
  },
  {
    slug: 'ad-of-the-week-3-course-dinner',
    subHead: 'Creative',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/6365a52f3b3b336190d5c22f_Untitled%20design%20%2813%29-p-1080.png',
    title: 'Ad of the Week: 3 Course Dinner',
    author: 'Kaylie Mings',
    date: 'November 4, 2022',
    description: "This post kicks off our new series, Ad of the week, where we will highlight our most successful ads, while going in depth to explain what has contributed to their positive results. At Blitz we're constantly testing new creative concepts, and we want to share our insights with the broader community too. Digital marketing experts estimate that most Americans are exposed to around 4,000 to 10,000 ads each day...",
    content: <PostThreeCourseDinner />
  },
  {
    slug: 'the-rise-of-tiktok',
    subHead: 'Marketing Strategy',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62f643b4de8e3e6eb14194b8_The%20Rise%20of%20TikTok-p-1080.jpg',
    title: 'The Rise of TikTok',
    author: 'Mayank Kumar',
    date: 'August 12, 2022',
    description: "When Six Degrees was born in May of 1997, few thought a website aggregating contacts had any future. Not even the hype of early 2000s sites like Friendster, Orkut and MySpace could indicate the scope and influence these social networking sites would have in the next few years. Even though quite a few existed before, it was Facebook that connected the world in a way no other site could do, truly creating a global social network. From there on, social sites proliferated...",
    authorImage: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62d929ac66c187a91ac2a4cf_Mayank.jpg',
    content: <PostRiseOfTikTok />
  },
  {
    slug: 'digital-marketing-mistakes-that-are-killing-your-startup',
    subHead: 'Marketing Strategy',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62d78c79795e0756ad8cefde_BlogPost1-p-1080.jpg',
    title: 'Digital Marketing Mistakes That Are Killing Your Startup',
    author: 'Pooja Raj',
    date: 'July 19, 2022',
    description: "The story of marketing, or helping businesses to grow and flourish via word of mouth or promotion, is a tale as old as civilization itself. The core principles of marketing haven't changed much from displaying your wares appealingly in the market and asking your friends to recommend you to potential buyers. Sure, the tools have evolved from simple word of mouth, to print, to radio, to television, to the internet, and now to fast moving social media, and the way advertisers approach marketing has evolved too...",
    authorImage: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62d7e383c2b0092bec213e77_Pooja%20Raj.jpg',
    content: <PostMarketingMistakes />
  },
  {
    slug: 'influencer-marketing-101',
    subHead: 'Influencers',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62bb7736c1a8e06fdfba13ea_social-media-gbee6aa59d_1920-p-1080.jpeg',
    title: 'Effective Influencer Marketing 101',
    author: 'Arturo Hurtado',
    date: 'June 28, 2022',
    description: "If you had asked what influencer marketing was 10 years ago, most people wouldn't have the slightest clue. Today, influencer marketing is one of the most recognizable forms of marketing and sits at a $13 billion dollar market size. It's clear that influencer marketing has quickly grown to be one of the most dynamic and effective marketing strategies we have available, and this growth is expected to continue past a $100 billion market size by 2030. Clearly, this new form of marketing is here to stay, and as marketers...",
    authorImage: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62bb78b2e140bf23177020a9_IMG_6078%203.jpg',
    content: <PostInfluencerMarketing />
  },
  {
    slug: 'abcs-of-a-b-testing',
    subHead: 'Campaign Management',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62da882067f1ce662d1356cb_A_B%20Testing-p-1080.jpg',
    title: "The ABC's of A/B Testing",
    author: 'Mohit Sardana',
    date: 'June 22, 2022',
    description: "\"Creative is King\" If you are a part of the advertising industry in any shape or form, it is almost impossible to never have heard this expression. As much as simplistic it might be, it is true and holds even more importance in this post-IDFA world. Creative is King, and every single one of us is trying to find the best recipe to churn out winning ads day in and day out. From ideation to implementation, it is a painstakingly slow process of testing and developing new creative without any guarantee of success",
    authorImage: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62de48bf63185e70eb6dbb29_Mohit%20Sardana.jpg',
    content: <PostABTesting />
  },
  {
    slug: 'how-to-create-a-winning-ad',
    subHead: 'Creative',
    image: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62e13eb26ee492afe5ae3f4c_winnning%20ad-p-1080.jpg',
    title: 'How to Create a Winning Ad',
    author: 'Cordelia Duff',
    date: 'May 27, 2022',
    description: "Anyone who has run a successful paid social campaign knows the significance of good ad creative. A winning asset can consistently and cheaply drive thousands of conversions for months on end, continuing to perform seemingly in perpetuity. So of course the question becomes, how do you create that winning ad? At Blitz Growth, we generate and test thousands of assets each month. It's nearly impossible to predict what will perform ahead of time, so we've built out our tech and our team to support the maximal volume of creative testing that our clients' budgets can support...",
    authorImage: 'https://uploads-ssl.webflow.com/61d87461e5ca7037d9c5a9d1/62a9040e157663272e198593_cordeliaduff.jpeg',
    content: <PostCreateWinningAd />
  }
];

export default Posts;